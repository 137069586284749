import React, { Fragment } from "react";
import moment from "moment/moment";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  },
  logoBox: {
    padding: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
    // borderRight: `1px solid ${theme.palette.divider}`
  },
  logo: {
    height: "80px",
    // margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      height: "110px"
    }
  },
  info: {
    padding: theme.spacing(2),
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  collection: {
    fontSize: "0.6rem",
    lineHeight: 1.1,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0)
  },
  title: {
    fontSize: "1.9rem",
    lineHeight: 1.1,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem"
    }
  },
  message: {
    fontSize: "0.7rem",
    lineHeight: 1.1,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0)
  }
}));

function DocumentHeader(props) {
  const classes = useStyles();
  return (
    <Fragment>
      <Box className={classes.root}>
        <Box className={classes.logoBox}>
          <img className={classes.logo} src={props.logo} />
        </Box>
        <Box className={classes.info}>
          {props.collection && (
            <Typography className={classes.collection}>
              {props.collection}
            </Typography>
          )}
          {props.title && (
            <Typography className={classes.title}>{props.title}</Typography>
          )}
          {props.message && (
            <Typography className={classes.message}>{props.message}</Typography>
          )}

          {props.author && (
            <Typography variant="subtitle2">
              Created by {props.author}{" "}
              {moment(props.created).format("DD-MMM-YYYY HH:MM")}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
    </Fragment>
  );
}

export default DocumentHeader;
