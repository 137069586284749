import React, { useState } from "react";
import {
  FormControlLabel,
  Switch,
  Grid,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

function ThemeSetting() {
  const storeTheme = useSelector((state) => state.theme.theme);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    theme: storeTheme === "dark" ? true : false
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    localStorage.setItem("theme", state.theme === false ? "dark" : "light");
    dispatch({
      type: "UPDATE_THEME",
      payload: {
        theme: state.theme === false ? "dark" : "light"
      }
    });
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Display" />
        <CardContent>
          <FormControlLabel
            control={
              <Switch
                name="theme"
                checked={state.theme}
                onChange={handleChange}
              />
            }
            label={state.theme === false ? "Light Mode" : "Dark Mode"}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ThemeSetting;
