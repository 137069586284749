import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
    cursor: "pointer"
  },
  imageFull: {
    height: "100%",
    width: "100%",
    overflow: "scroll",
    marginBottom: theme.spacing(1)
  },
  caption: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize
  }
}));

function ImageBlock(props) {
  const { url, note } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Box
            onClick={handleClickOpen}
            style={{ backgroundImage: `url(${url})` }}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.caption}>{note}</Typography>
        </Grid>
      </Grid>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            component="img"
            src={url}
            alt="image"
            // style={{ backgroundImage: `url(${url})` }}
            className={classes.imageFull}
          />
          <DialogContentText>{note}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

ImageBlock.propTypes = {
  note: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default ImageBlock;
