import React from "react";
import {
  RenderTextField,
  RenderFormSection
} from "@austere-monorepo/components";
import { Field } from "redux-form";

const Enquire = (props) => (
  <RenderFormSection heading="Enquire" id={props.id}>
    <Field
      name="symptoms"
      label="Signs & Symptoms"
      multiline
      component={RenderTextField}
    />
    <Field
      name="allergies"
      label="Allergies"
      placeholder="Allergies (or NKA)"
      type="text"
      multiline
      component={RenderTextField}
    />
    <Field
      name="medication"
      type="text"
      label="Medication"
      placeholder="List medication names, dosages, frequency & last taken"
      multiline
      component={RenderTextField}
    />
    <Field
      name="past_history"
      label="Past history"
      placeholder="Past history relevant to the injury/illness"
      type="text"
      multiline
      component={RenderTextField}
    />
    <Field
      name="last_inout"
      label="Last in & out"
      placeholder="Recent foods, drinks and toilet history"
      type="text"
      multiline
      component={RenderTextField}
    />
    <Field
      name="events"
      label="Events"
      placeholder="Events leading up to injury/ illness"
      type="text"
      multiline
      component={RenderTextField}
    />
  </RenderFormSection>
);

export default Enquire;
