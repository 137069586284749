import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: '2px',
    margin: theme.spacing(2, 0)
  }
}));

function RenderFormDivider() {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Divider className={classes.divider} />
    </Grid>
  );
}

export default RenderFormDivider;
