import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import useClipboard from 'react-use-clipboard';
import { Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function CopyClipboard() {
  const [isCopied, setCopied] = useClipboard(window.location.href, {
    successDuration: 3000
  });

  return (
    <Tooltip
      open={isCopied}
      title={
        isCopied
          ? 'Current URL copied to clipboard'
          : 'Click to copy current URL'
      }
    >
      <IconButton color="inherit" onClick={setCopied}>
        {isCopied ? <CheckCircleOutlineIcon /> : <ShareIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default CopyClipboard;
