import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  cross: {
    margin: '-15px 0 0 -15px',
    left: '50%',
    top: '50%',
    display: 'block',
    position: 'absolute',
    zIndex: 800,
    fontSize: '16px'
  }
}))

function Center() {
  const classes = useStyles()
  function Cross() {
    return (
      <svg viewBox="0 0 100 100" height="30px" width="30px">
        <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
          <circle cx={50.028} cy={50.219} r={3.923} strokeWidth={2} />
          <path
            d="M4.973 54.424h31.768a4.204 4.204 0 100-8.409H4.973A4.203 4.203 0 00.77 50.22a4.203 4.203 0 004.204 4.205zM54.232 5.165a4.204 4.204 0 10-8.408 0v31.767a4.204 4.204 0 108.408 0zM99.288 50.22a4.204 4.204 0 00-4.204-4.205H63.317a4.204 4.204 0 100 8.409h31.767a4.205 4.205 0 004.204-4.205zM45.823 95.273a4.204 4.204 0 108.409 0V63.506a4.204 4.204 0 10-8.409 0v31.768z"
            strokeWidth={3}
          />
        </g>
      </svg>
    )
  }
  return (
    <div className={classes.cross}>
      <Cross />
    </div>
  )
}

export default Center
