import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import { notifications, theme, sw } from "@austere-monorepo/components";
import mapState from "./reducer_map";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  notifications,
  mapState,
  theme,
  sw
});

export default rootReducer;
