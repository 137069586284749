import { Field } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { getFirebase } from "react-redux-firebase";
import {
  StyledWrapper,
  RenderTextField,
  RenderPasswordField,
  validate,
  history
} from "@austere-monorepo/components";
import { Typography, Button, Card, Grid, Divider } from "@material-ui/core";

function handleLogin(creds) {
  const firebase = getFirebase();
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Logging in...",
          options: {
            variant: "info"
          }
        }
      });
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password)
        .then(() => {
          dispatch({
            type: "ENQUEUE_SNACKBAR",
            notification: {
              key: new Date().getTime() + Math.random(),
              message: "Login success",
              options: {
                variant: "success"
              }
            }
          });
        });
      // Check if the user has dismissed the install prompt. If this is a new browser, prompt the user to install
      if (localStorage.getItem("installPrompt") === null) {
        localStorage.setItem("installPrompt", true);
      }
      history.push("/");
      // history.go();
    } catch (error) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Login failed ${error.message}`,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
}

function LoginForm(props) {
  const { handleSubmit, handleLogin } = props;
  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <StyledWrapper maxWidth={"sm"}>
        <Card>
          <Typography gutterBottom variant="h2" align="center">
            Login
          </Typography>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Field
              name="email"
              label="Email"
              type="text"
              placeholder="Email Address"
              component={RenderTextField}
            />

            <Field
              name="password"
              label="Password"
              placeholder="Password"
              component={RenderPasswordField}
            />

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Login
              </Button>
            </Grid>

            {/* <Grid item xs={12}>
              <GoogleButton onClick={handleSocialLogin('google')} />
            </Grid> */}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Typography style={{ padding: "5px 10px" }}>
              No account? <Link to="/register">Register here</Link>
            </Typography>

            <Typography style={{ padding: "5px 10px" }}>
              Forgot password? <Link to="/reset-password">Reset here</Link>
            </Typography>

            <Typography style={{ padding: "5px 10px" }}>
              Issues?{" "}
              <Typography
                component="a"
                target="_blank"
                href="https://austererisk.com/support"
              >
                austererisk.com/support
              </Typography>
            </Typography>
          </Grid>
        </Card>
      </StyledWrapper>
    </form>
  );
}

export default compose(
  connect(null, {
    handleLogin
  }),
  reduxForm({
    form: "loginForm",
    forceUnregisterOnUnmount: true,
    onSubmit: handleLogin,
    validate
  })
)(LoginForm);
