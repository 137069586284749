import React from "react";
import {
  RenderTextField,
  RenderFormSection
} from "@austere-monorepo/components";
import { Field } from "redux-form";

const Summary = () => (
  <RenderFormSection heading="Summary">
    <Field
      style={{ margin: "10px 0" }}
      name="ems_called"
      label="EMS Called"
      type="time"
      placeholder="Time (tap to select)"
      component={RenderTextField}
      md={4}
    />
    <Field
      style={{ margin: "10px 0" }}
      name="ems_arrived"
      label="EMS Arrived"
      type="time"
      placeholder="Time (tap to select)"
      component={RenderTextField}
      md={4}
    />
    <Field
      style={{ margin: "10px 0" }}
      name="ems_departed"
      label="EMS Departed"
      type="time"
      placeholder="Time (tap to select)"
      component={RenderTextField}
      md={4}
    />
    <Field
      name="incident"
      label="Incident description"
      type="text"
      multiline
      placeholder="Use this area to give a detailed account of the incident. You may include information that is not included in the previous drop-down boxes."
      component={RenderTextField}
      md={12}
    />
    <Field
      name="treatment"
      label="Treatment provided"
      type="text"
      multiline
      placeholder="Detail any treatment you gave the patient."
      component={RenderTextField}
      md={12}
    />
    <Field
      name="hazards"
      label="Hazards"
      type="text"
      multiline
      placeholder="Are there any hazards for approaching EMS or other assistance? Will there be hazards during evacuation?"
      component={RenderTextField}
      md={12}
    />
    <Field
      name="other_info"
      label="Other information"
      type="text"
      multiline
      placeholder="Is there any further information about the incident, scene or evacuation that you would like to document?"
      component={RenderTextField}
      md={12}
    />
  </RenderFormSection>
);

export default Summary;
