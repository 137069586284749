import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HomeIcon from "@material-ui/icons/Home";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { StyledWrapper } from "@austere-monorepo/components";
function Subscriber() {
  return (
    <StyledWrapper>
      <Alert
        style={{ margin: "16px" }}
        icon={<MonetizationOnIcon fontSize="inherit" />}
        severity="warning"
      >
        <AlertTitle>Subscriber Only Content</AlertTitle>
        This section is only accessible to subscribers. Go to the billing page
        to view plans and subscribe
      </Alert>
      <Box display="flex" justifyContent="center">
        <Button
          style={{ margin: "10px" }}
          startIcon={<MonetizationOnIcon />}
          color="primary"
          variant="outlined"
          component={Link}
          to={"/account/"}
          align="center"
        >
          View Plans
        </Button>
        <Button
          style={{ margin: "10px" }}
          startIcon={<HomeIcon />}
          color="primary"
          variant="outlined"
          component={Link}
          to={"/"}
          align="center"
        >
          Home
        </Button>
      </Box>
    </StyledWrapper>
  );
}

export default Subscriber;
