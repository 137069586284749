import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography
} from "@material-ui/core";
import {
  HelpButton,
  Markdown,
  SaveButton,
  DeleteButton,
  GoBackButton,
  EditButton,
  MenuButton
} from "@austere-monorepo/components";

const useStyles = makeStyles((theme) => ({
  actionBarRoot: {
    position: "sticky",
    top: 64,
    width: "100%",
    zIndex: 1050,
    height: "64px",
    [theme.breakpoints.down("sm")]: {
      top: 56
    }
  },
  actionBar: {
    display: "flex",
    padding: theme.spacing(0.5, 1),
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper
  },
  leftBox: {
    display: "flex",
    alignItems: "center"
  },
  rightBox: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontSize: "0.95rem",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem"
    }
  }
}));

function ActionBar(props) {
  const { helpDoc } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Box className={classes.root}>
        <Box className={classes.actionBar}>
          <Box className={classes.leftBox}>
            <GoBackButton />

            {props.title && (
              <Typography className={classes.title} component="h1">
                {props.title}
              </Typography>
            )}
          </Box>
          <Box className={classes.rightBox}>
            <Grid container>
              {props.helpDoc && <HelpButton onClick={handleClickOpen} />}
              {props.delete && <DeleteButton onClick={props.delete} />}
              {props.edit && (
                <EditButton
                  collection={props.collection}
                  id={props.id}
                  doc={props.doc}
                />
              )}
              {props.save && <SaveButton />}
              {props.menu && <MenuButton onClick={props.onClickMenu} />}
              {props.children}
            </Grid>
          </Box>
        </Box>
        {/* </AppBar> */}

        {/* <Grid container justify='space-between' alignItems='center' className={classes.root}>
        <Grid item xs={0}>
          <GoBackButton />
        </Grid>

        <Grid item xs={10}>
          <Button size='large' startIcon={props.icon} className={classes.title}>
            {props.title}
          </Button>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {props.helpDoc && <HelpButton onClick={handleClickOpen} />}
            {props.delete && <DeleteButton onClick={props.delete} />}
            {props.edit && <EditButton collection={props.collection} id={props.id} doc={props.doc} />}
            {props.save && <SaveButton />}
            {props.menu && <MenuButton onClick={props.onClickMenu} />}
            {props.children}
          </Grid>
        </Grid>
      </Grid> */}
      </Box>
      {/* {helpDoc && (
        <Dialog open={open} onClose={handleClose} scroll={'paper'}>
         <DialogTitle disableTypography id="scroll-dialog">
            {props.title + ' ' + 'Help'}
          </DialogTitle>
          <DialogContent>
            <Markdown doc={helpDoc} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </Fragment>
  );
}

export default ActionBar;
