import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MapComponent } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";

function Map({ _notes, form }) {
  const notes = _notes && _notes.filter((note) => note.latitude != null);
  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  const baseURL = "https://api.mapbox.com/styles/v1/austere-rm/";
  const endURL = "/tiles/256/{z}/{x}/{y}?access_token=";
  const baseLayers = [
    {
      title: "Base map",
      url: baseURL + "ck8jkyb5q0a0j1imlxiwyhgdb" + endURL + token
    },
    {
      title: "Dark Base Map",
      url: baseURL + "ck8a5vu090r621jo5iabh34hu" + endURL + token
    },
    {
      title: "Topographic",
      url: baseURL + "ck8jl2q820ww01imhc46advjb" + endURL + token
    },
    {
      title: "Satellite",
      url: baseURL + "ck8jl922y08nn1ioc2f5g22m0" + endURL + token
    }
  ];
  const dataLayers = [
    {
      title: "N.O.T.E.S.",
      data: notes,
      collection: "notes",
      type: "patient"
    }
  ];

  return (
    <MapComponent
      form={form}
      baseLayers={baseLayers}
      dataLayers={dataLayers}
      // dragging={form ? false : true}
      dragging
      zoomButtons
      coordinates
      // search={form ? false : true}
      locate
    />
  );
}

// function MiniMap(props) {
//   const classes = useStyles();
//   const lat = Number(props.lat);
//   const lng = Number(props.lng);
//   const zoom = props.zoom ? Number(props.zoom) : 12;

//   return (
//     <Fragment>
//       <MapComponent
//         id={"mini-map"}
//         lat={lat}
//         lng={lng}
//         zoom={zoom}
//         dragging={false}
//         zoomButtons
//       />
//       <div className={classes.link}>
//         <Typography gutterBottom variant="body2">
//           Coordinates: {lat},{lng}
//         </Typography>
//         <Typography
//           variant="body2"
//           component={Link}
//           to={"/map#" + lat + "," + lng + "," + zoom + "z"}
//         >
//           View on map
//         </Typography>
//       </div>
//     </Fragment>
//   );
// }

const mapStateToProps = ({ firestore: { ordered } }) => ({
  _notes:
    ordered.notes &&
    ordered.notes.filter((note) => note.latitude && note.longitude)
});

export default compose(connect(mapStateToProps))(Map);
