import { Fragment } from "react";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
// Styles
import {
  StyledWrapper,
  RenderTextField,
  RenderPasswordField,
  validate
} from "@austere-monorepo/components";
import { Field, reduxForm } from "redux-form";
import {
  Typography,
  Button,
  Card,
  Container,
  Grid,
  Tooltip,
  Divider
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const registerUser = (user) => async (dispatch) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  try {
    // Let the browser know this is a new user and prompt to install
    localStorage.setItem("installPrompt", true);
    // create the user in firebase auth
    let createdUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);
    console.log(createdUser);
    // update the auth profile
    await createdUser.user.updateProfile({
      displayName: user.displayName
    });
    // create a new profile in firestore
    let newUser = {
      displayName: user.displayName,
      role: 1,
      email: user.email,
      id: createdUser.user.uid,
      created_at: firestore.FieldValue.serverTimestamp()
    };
    await firestore.set(`users/${createdUser.user.uid}`, { ...newUser });
    console.log("User Created", newUser);
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Register Success",
        options: {
          variant: "success"
        }
      }
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Registration Failed: " + error,
        options: {
          variant: "error"
        }
      }
    });
  }
};

function RegisterForm(props) {
  const { registerUser, handleSubmit, submitting, error } = props;
  const authEmpty = useSelector((state) => state.firebase.auth.isEmpty);
  return (
    <Fragment>
      {!authEmpty && <Redirect to="/" />}
      {authEmpty && (
        <form onSubmit={handleSubmit(registerUser)}>
          <StyledWrapper maxWidth={"sm"}>
            <Card>
              <Typography
                style={{ marginBottom: "20px" }}
                variant="h2"
                align="center"
              >
                Register
              </Typography>
              <Grid container justify="center" alignItems="center" spacing={4}>
                <Field
                  name="displayName"
                  label="First name & last name"
                  type="text"
                  placeholder="First name & last name."
                  component={RenderTextField}
                  md={6}
                />
                <Field
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Email Address"
                  component={RenderTextField}
                  md={6}
                />
                <Field
                  name="password"
                  label="Password"
                  placeholder="Password"
                  component={RenderPasswordField}
                  md={6}
                />
                <Field
                  name="cpassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  component={RenderPasswordField}
                  md={6}
                />

                <Grid item xs={12}>
                  <Tooltip title={"Register with email & password"}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || error}
                    >
                      Register
                    </Button>
                  </Tooltip>
                </Grid>

                {/* <Grid item xs={12}>
              <GoogleButton onClick={() => socialLogin('google')} />
            </Grid> */}

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Typography style={{ padding: "5px 10px" }}>
                  Already registered? <Link to="/login"> Login here</Link>
                </Typography>

                <Typography style={{ padding: "5px 10px" }}>
                  Forgot password? <Link to="/reset-password">Reset here</Link>
                </Typography>

                <Typography style={{ padding: "5px 10px" }}>
                  Issues?{" "}
                  <Typography
                    component="a"
                    target="_blank"
                    href="https://austererisk.com/support"
                  >
                    austererisk.com/support
                  </Typography>
                </Typography>
              </Grid>
            </Card>
          </StyledWrapper>
        </form>
      )}
    </Fragment>
  );
}

export default compose(
  connect(null, { registerUser }),
  reduxForm({
    form: "registerForm",
    validate
  })
)(RegisterForm);
