import {
  defaultLat,
  defaultLng,
  defaultZoom,
  defaultMapBase,
  defaultMapData
} from "config";

const initState = {
  lat: defaultLat,
  lng: defaultLng,
  accuracy: null,
  zoom: defaultZoom,
  base: defaultMapBase,
  data: defaultMapData
};

function mapState(state = initState, action) {
  switch (action.type) {
    case "UPDATE_MAP_CENTER":
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng
      };
    case "UPDATE_MAP_ZOOM":
      return {
        ...state,
        zoom: action.payload.zoom
      };
    case "UPDATE_MAP_BASE":
      return {
        ...state,
        base: action.payload.base
      };
    case "UPDATE_DATA_LAYERS":
      return {
        ...state,
        data: action.payload.data
      };
    case "UPDATE_MAP_ACCURACY":
      return {
        ...state,
        accuracy: action.payload.accuracy
      };
    default:
      return state;
  }
}

export default mapState;
