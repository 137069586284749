import React, { PureComponent } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withSnackbar } from "notistack";
import {
  StyledTabs,
  UserBilling,
  UserAccount
} from "@austere-monorepo/components";
import UserSettings from "./UserSettings";
import { stripePublishKey, functionsLocation, products } from "config";
import ProductFeatures from "./ProductFeatures.md";
import ProductDescription from "./ProductDescription.md";

class UserProfile extends PureComponent {
  render() {
    const { _profile, _user, _products } = this.props;

    console.log(_products);
    return (
      <StyledTabs
        base={"account"}
        tabs={[
          {
            label: "account",
            path: "account",
            content: (
              <UserAccount
                user={_user}
                products={_products}
                profile={_profile}
              />
            )
          },
          {
            label: "Settings",
            path: "settings",
            content: <UserSettings />
          },
          {
            label: "Billing",
            path: "billing",
            content: (
              <UserBilling
                user={_user}
                // isSubscriber={_role}
                // subscriptions={_subscriptions}
                stripePublishKey={stripePublishKey}
                functionsLocation={functionsLocation}
                products={products}
                features={ProductFeatures}
                description={ProductDescription}
              />
            )
          }
        ]}
      />
    );
  }
}

const mapStateToProps = ({
  firebase: { auth },
  firestore: { ordered, data }
  // role: { role }
}) => ({
  _avatar: ordered.owner && ordered.owner[0].photoURL,
  _profile: data && data.owner,
  _user: auth,
  // _role: data.owner && data.owner.role === 'premium' ? true : false,
  _subscriptions: data.subscriptions && data.subscriptions,
  _products: ordered.products && ordered.products,
  initialValues: ordered.owner && {
    ...ordered.owner[0],
    email: auth && auth.email
  }
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: "profileForm",

    destroyOnUnmount: true
  }),
  firestoreConnect((props) => [{ collection: "products", storeAs: "products" }])
)(withSnackbar(UserProfile));
