import React, { useEffect, useState } from "react";
import {
  StyledSideView,
  StyledWrapper,
  getHeading
} from "@austere-monorepo/components";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2, 3)
  }
}));

function FormWrapper(props) {
  const { headings, children, title, actionBar } = props;

  const classes = useStyles();

  const [activeHeading, setActiveHeading] = useState(null);

  useEffect(() => {
    if (activeHeading !== null) {
      document.getElementById(activeHeading).scrollIntoView();
    }
  }, [activeHeading]);

  return (
    <StyledSideView
      actionBar={actionBar}
      title={title}
      sidebar={
        headings ? (
          <List dense disablePadding>
            {headings.map((heading, i) => (
              <ListItem
                key={i}
                button
                className={classes.doc}
                selected={getHeading(heading) === activeHeading}
                onClick={() => setActiveHeading(getHeading(heading))}
              >
                <ListItemText primary={heading} />
              </ListItem>
            ))}
          </List>
        ) : null
      }
      content={
        <StyledWrapper>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </StyledWrapper>
      }
      {...props}
    />
  );
}

FormWrapper.propTypes = {
  title: PropTypes.string.isRequired
};

export default FormWrapper;
