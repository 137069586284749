import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// Icons
import AddIcon from "@material-ui/icons/Add";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import EmailIcon from "@material-ui/icons/MailOutline";
import LocateIcon from "@material-ui/icons/LocationOn";
import TocIcon from "@material-ui/icons/Toc";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

// Styles
import {
  Button,
  Fab,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  SvgIcon,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5, 1)
  },
  icon: {
    fontSize: "24px"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "20px"
    // }
  },
  fab: {
    position: "fixed",
    right: "30px",
    zIndex: 999
  },
  blue: {
    color: theme.palette.info.dark
  },
  red: {
    color: theme.palette.error.main
  }
}));

export function EditButton({ collection, id, doc }) {
  const classes = useStyles();
  return (
    <Grid item>
      <Link to={"/" + collection + "/edit/" + id}>
        <Tooltip title={"Edit " + doc}>
          <IconButton className={classes.iconButton}>
            <EditIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Link>
    </Grid>
  );
}

export function PdfButton(props) {
  // const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Export as PDF">
        <Button size="small" startIcon={<PdfIcon />} onClick={props.onClick}>
          PDF
        </Button>
      </Tooltip>
    </Grid>
  );
}

export function TocButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Contents">
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={props.onClick}
        >
          <TocIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function SearchButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Search">
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={props.onClick}
        >
          <SearchIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function MenuButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Hidden smUp implementation="css">
        <Tooltip title="Menu">
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={props.onClick}
          >
            <MenuIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Hidden>
    </Grid>
  );
}

export function HelpButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Help">
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={props.onClick}
        >
          <HelpIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function AddButton(props) {
  const classes = useStyles();
  return (
    <div className={classes.fab} style={{ bottom: "25px" }}>
      <Tooltip title={"Add " + props.doc}>
        <Fab
          color="secondary"
          component={Link}
          to={"/" + props.collection + "/add"}
        >
          <AddIcon fontSize="default" style={{ color: "#ffffff" }} />
        </Fab>
      </Tooltip>
    </div>
  );
}

export function LinkFabButton(props) {
  const classes = useStyles();
  return (
    <div className={classes.fab} style={{ bottom: props.bottom }}>
      <Tooltip title={"To " + props.to}>
        <Fab
          className={classes.blue}
          component={Link}
          to={"/" + props.collection}
        >
          {props.children}
        </Fab>
      </Tooltip>
    </div>
  );
}

export function DeleteButton(props) {
  const { deleteForm } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    deleteForm();
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Grid item>
        <Tooltip title="Delete form">
          <IconButton
            className={`${classes.iconButton} ${classes.red}`}
            onClick={handleClickOpen}
            size="small"
          >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this form? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            className={classes.red}
            onClick={handleDelete}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

// export function SaveButton(props) {
//   const classes = useStyles();
//   return (
//     <Tooltip title={'Save'}>
//       <Fab
//         className={`${classes.fab} ${classes.blue} ${classes.bottom}`}
//         type="submit"
//         disabled={props.submitting}
//       >
//         <SaveIcon fontSize="large" style={{ color: '#ffffff' }} />
//       </Fab>
//     </Tooltip>
//   );
// }

export function SaveButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title="Save form">
        <IconButton
          className={`${classes.iconButton} ${classes.blue}`}
          type="submit"
          size="small"
          disabled={props.submitting || props.error}
        >
          <SaveIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function GoBackButton(props) {
  const classes = useStyles();
  let history = useHistory();
  let back = () => {
    history.goBack();
  };
  return (
    <Grid item>
      <Tooltip title="Cancel / Go back">
        <IconButton className={classes.iconButton} onClick={back}>
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function SendButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title={"Save and send"}>
        <IconButton
          className={`${classes.iconButton} ${classes.blue}`}
          // type='submit'
          size="small"
          disabled={props.submitting || props.error}
          onClick={props.onClick}
        >
          <EmailIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function LocateButton(props) {
  // const classes = useStyles();
  return (
    <div style={{ margin: "5px", display: "inline-block" }}>
      <Tooltip title="Locate">
        <Link to={"/map#" + props.lat + "," + props.lng + ",18z"}>
          <Button
            style={{
              padding: "5px 10px 5px 5px"
            }}
            onClick={props.onClick}
          >
            <LocateIcon />
            <p style={{ paddingLeft: "5px" }}>Locate</p>
          </Button>
        </Link>
      </Tooltip>
    </div>
  );
}

export function ViewButton(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Tooltip title={"View"}>
        <IconButton
          className={classes.iconButton}
          component={Link}
          to={props.to}
        >
          <SearchIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function LinkButton(props) {
  // const classes = useStyles();
  return (
    <div style={{ margin: "5px", display: "inline-block" }}>
      <Tooltip title={props.text}>
        <Link to={props.to}>
          <Button
            inline
            style={{
              padding: "5px 10px 5px 5px"
            }}
          >
            <Typography variant="body2" style={{ paddingLeft: "5px" }}>
              {props.text}
            </Typography>
          </Button>
        </Link>
      </Tooltip>
    </div>
  );
}

// export default { PdfButton }

export function StyledIconButton(props) {
  const classes = useStyles();
  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        style={{ color: props.color }}
        className={classes.root}
        component={props.to && Link}
        to={props.to && props.to}
        children={props.children}
        onClick={props.onClick && props.onClick}
      />
    </Tooltip>
  );
}

export function GoogleButton(props) {
  return (
    <Tooltip title={"Continue with Google"}>
      <Button
        fullWidth
        variant="contained"
        startIcon={
          <SvgIcon viewBox={"0 0 533.5 544.3"}>
            <path
              d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
              fill="#4285f4"
            />
            <path
              d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
              fill="#34a853"
            />
            <path
              d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
              fill="#fbbc04"
            />
            <path
              d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
              fill="#ea4335"
            />
          </SvgIcon>
        }
        onClick={props.onClick && props.onClick}
      >
        Continue with Google
      </Button>
    </Tooltip>
  );
}
