import React, { Fragment } from "react";
import { StyledListLink } from "@austere-monorepo/components";

function NoData(props) {
  const message = props.message ? props.message : "No data";
  const item = <StyledListLink primary={message} />;
  return (
    <Fragment>
      {props.array && props.array.length === 0 && item}
      {!props.array && item}
    </Fragment>
  );
}

export default NoData;
