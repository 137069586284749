import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: '10px',
    zIndex: 500,
    height: '36px',
    maxWidth: '36px',
    minWidth: '36px',
    background: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.paper
    },
    color: theme.palette.text.secondary
  }
}));

function MapButton(props) {
  const classes = useStyles();
  return (
    <Tooltip title={props.title}>
      <Button
        style={{
          top: 10 + props.order * 46 + 'px'
          //  top: props.order === 1 ? '10px' : (props.order -1) * 50 + 'px',
        }}
        component={props.component}
        to={props.to}
        className={classes.root}
        variant="outlined"
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
}

export default MapButton;
