import React, { Fragment, useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  getHash,
  getHeading,
  StyledSideView,
  StyledWrapper,
  RenderFormSection,
  EditButton
} from "@austere-monorepo/components";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 500,
    marginRight: theme.spacing(1)
  },
  subHeading: {
    fontWeight: 500,
    color: theme.palette.text.secondary
  }
}));

function StyledViewSection({
  rows,
  title,
  collection,
  doc,
  id,
  editRole,
  author,
  updated
}) {
  const location = useLocation();
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  const classes = useStyles();
  const sidebar = useMemo(
    () => (
      <List dense disablePadding>
        {rows &&
          rows.map((section, i) => (
            <ListItem
              key={i}
              button
              component={HashLink}
              to={getHash(section.heading)}
              selected={location.hash === getHash(section.heading)}
            >
              <ListItemText
                // className={classes.listText}
                primary={section.heading}
              />
            </ListItem>
          ))}
      </List>
    ),
    [location]
  );

  console.log(rows);

  return (
    <StyledSideView
      title={title}
      actionBar={
        _role >= editRole && (
          <EditButton collection={collection} id={id} doc={doc} />
        )
      }
      content={
        <StyledWrapper>
          <Grid container spacing={2}>
            {rows &&
              rows.map((section, i) => (
                <RenderFormSection heading={section.heading}>
                  {section.items &&
                    section.items.map((item, i) => (
                      <Grid key={i} item md={item.md ? item.md : 12} xs={12}>
                        {item.heading && (
                          <Fragment>
                            <Typography
                              id={item.heading && getHeading(item.heading)}
                              display="inline"
                              className={classes.heading}
                            >
                              {`${item.heading}:`}
                            </Typography>

                            <Typography
                              display="inline"
                              component={
                                item.link
                                  ? item.link.startsWith("https")
                                    ? "a"
                                    : Link
                                  : null
                              }
                              to={
                                item.link
                                  ? item.link.startsWith("/")
                                    ? item.link
                                    : null
                                  : null
                              }
                              href={
                                item.link
                                  ? item.link.startsWith("https")
                                    ? item.link
                                    : null
                                  : null
                              }
                            >
                              {item.value !== undefined ? item.value : ""}
                            </Typography>
                          </Fragment>
                        )}
                        {item.subHeading && (
                          <Fragment>
                            <Divider style={{ marginBottom: "15px" }} />
                            <Typography
                              id={item.heading && getHeading(item.subHeading)}
                              display="inline"
                              className={classes.subHeading}
                            >
                              {`${item.subHeading} `}
                            </Typography>
                          </Fragment>
                        )}
                      </Grid>
                    ))}
                </RenderFormSection>
              ))}
          </Grid>
        </StyledWrapper>
      }
      sidebar={sidebar}
    />
  );
}

export default StyledViewSection;
