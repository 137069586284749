import { Document } from "@austere-monorepo/components";
import HelpDoc from "./help.md";

function Help() {
  return (
    <Document
      doc={HelpDoc}
      title={"Help"}
      collection={"Knowledge Base"}
      author={"Brad Stewart"}
    />
  );
}
export default Help;
