import React, { Fragment, useState } from "react";
import { connect, useDispatch, batch } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm, change } from "redux-form";
import { useMap } from "react-leaflet";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MapButton from "./MapButton";
import { Grid, Typography, Popover, Button, Slider, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RenderTextField, handleFormAdd } from "@austere-monorepo/components";
import { firestoreConnect } from "react-redux-firebase";
// import {  } from 'actions/onlineStatus'
import { withSnackbar } from "notistack";
import { Circle, Tooltip } from "react-leaflet";

const useStyles = makeStyles((theme) => ({
  legend: {
    padding: theme.spacing(1, 0.5),
    borderRadius: "4px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, 0.65)",
  },
  list: {
    padding: theme.spacing(2),
  },
}));

const formName = "geocircle";
const collection = "geopoints";

function AddCircle(props) {
  const { handleSubmit, pristine, reset, submitting } = props;

  const onSubmit = (formProps) => {
    const data = Object.assign(
      {
        author: props._userUID,
        authorName: props._authorName,
        created_at: new Date().getTime(),
      },
      formProps
    );
    props

      .then(() => {
        props.handleFormAdd(
          {
            formName: collection,
            formData: data,
          },
          "ONLINE"
        );
      })
      .catch(() => {
        props.handleFormAdd(
          {
            formName: collection,
            formData: data,
          },
          "OFFLINE"
        );
      });
  };

  const classes = useStyles();

  const map = useMap();

  const [anchorEl, setAnchorEl] = useState(false);

  const [plot, setPlot] = useState(false);

  const [radius, setRadius] = React.useState(200);

  const handleRadiusChange = (event, newRadius) => {
    setRadius(newRadius);
  };

  const [description, setDescription] = React.useState("");

  const handleDescriptionChange = (event, newDescription) => {
    setDescription(newDescription);
  };

  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setPlot(!plot);
  };

  // console.log(radius)

  batch(() => {
    dispatch(change(formName, "latitude", map.getCenter().lat));
    dispatch(change(formName, "longitude", map.getCenter().lng));
    dispatch(change(formName, "radius", radius * 10));
    dispatch(change(formName, "description", description));
    dispatch(change(formName, "type", "circle"));
  });

  const handleClose = () => {
    dispatch(reset(formName));
    setAnchorEl(null);
    setPlot(!plot);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <MapButton title={"Add an area circle"} onClick={handleOpen} order={7}>
        <AddCircleOutlineIcon fontSize='small' />
      </MapButton>
      <Popover
        className={classes.legend}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.list} container direction='column' justify='flex-start' spacing={2}>
            <Grid item>
              <Typography variant='body1' align='center' gutterBottom>
                Add Area Notes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Radius: {radius && Math.round((radius / 100) * 2)} km</FormLabel>
              <Slider min={10} max={10000} value={radius} onChange={handleRadiusChange} valueLabelDisplay='off' />
            </Grid>
            <Field
              name='description'
              label='Description'
              type='text'
              multiline
              component={RenderTextField}
              onChange={handleDescriptionChange}
            />
            <Grid item xs={12}>
              <Button
                style={{ marginRight: "30px" }}
                variant='contained'
                type='submit'
                onClick={handleClose}
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button size='small' variant='text' disabled={pristine || submitting} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Popover>
      {plot && (
        <Circle
          radius={radius && radius * 10}
          weight={1}
          color={"red"}
          fillColor={"red"}
          fillOpacity={0.1}
          center={[map.getCenter().lat, map.getCenter().lng]}
          key={Math.random()}
        >
          <Tooltip permanent opacity={1}>
            {props._authorName}: {description && description}
          </Tooltip>
        </Circle>
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ firebase: { auth } }) => ({
  _userUID: auth.uid,
  _authorName: auth.displayName,
});

export default compose(
  connect(
    mapStateToProps
    // {  handleFormAdd }
  ),
  reduxForm({
    form: formName,
  }),
  firestoreConnect()
)(withSnackbar(AddCircle));
