import React, { Fragment, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import useClipboard from "react-use-clipboard";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Button } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import { history } from "@austere-monorepo/components";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "180px",
    minWidth: "180px",
    maxWidth: "180px",
    left: "10px",
    bottom: "10px",
    zIndex: 999,
    background: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.paper
    }
  },
  icon: {
    fontSize: "15px"
  },
  green: {
    color: "green"
  },
  label: {
    justifyContent: "flex-start",
    fontSize: "0.75rem",
    letterSpacing: "0.05em",
    // fontWeight: "normal",
    color: theme.palette.text.secondary
  }
}));

function Coordinates({ visible }) {
  const map = useMap();

  const dispatch = useDispatch();

  const classes = useStyles();

  const [position, setPosition] = useState(map.getCenter());

  // const [bounds, setBounds] = useState(map.getBounds());

  const zoom = useSelector((state) => state.mapState && state.mapState.zoom);

  useMapEvents({
    move: () => {
      setPosition(map.getCenter());
      // setBounds(map.getBounds());
      // console.log(bounds);
    }
  });

  const lat = Number(position.lat.toFixed(5));
  const lng = Number(position.lng.toFixed(5));

  // var hash =
  //   "#" + Math.round(lat * 100000) / 100000 + "," + Math.round(lng * 100000) / 100000 + "," + map.getZoom() + "z";

  useMapEvents({
    moveend: () => {
      dispatch({
        type: "UPDATE_MAP_CENTER",
        payload: {
          lat: lat,
          lng: lng
        }
      });
      if (visible) {
        window.history.pushState(
          {
            /*empty state object*/
          },
          "map",
          `#${lat},${lng},${zoom}`
        );
      }
    }
  });

  const mapCenter = lat + "," + lng;

  const [isCopied, setCopied] = useClipboard(mapCenter, {
    successDuration: 2000
  });

  return (
    <Fragment>
      {visible && (
        <Tooltip
          title={
            isCopied
              ? "Coordinates copied to clipboard"
              : "Click to copy coordinates"
          }
        >
          <Button
            variant="outlined"
            size="small"
            onClick={setCopied}
            startIcon={
              isCopied ? (
                <CheckCircleOutlineIcon
                  className={`${classes.icon} ${classes.green}`}
                />
              ) : (
                <FileCopyIcon className={classes.icon} />
              )
            }
            classes={{
              root: classes.root,
              label: classes.label
            }}
          >
            {mapCenter}
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
}

export default Coordinates;
