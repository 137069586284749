import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import queryString from 'query-string';
// import { useHistory } from 'react-router-dom'
// import { StyledWrapper } from "@austere-monorepo/components";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0)
    // background: theme.palette.background.paper,
  },
  appBar: {
    position: "sticky",
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.appBar - 1,
    top: "56px",
    width: "calc(100vw - 200px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  tabs: {
    // fontSize: "0.7rem"
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index } = props;
  return (
    <Fragment>
      {value === index && <Box className={classes.root}>{children}</Box>}
    </Fragment>
  );
}

function StyledTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // const tabNames = [
  //   props.tabs &&
  //   props.tabs.map((tab, i) => {
  //     return tab.path
  //   })
  // ]

  // console.log(tabNames)

  // useEffect(() => {
  //   const values = queryString.parse(props.location.search)
  //   if (values.path) {
  //     setName({ name: tabNames.indexOf(values.tab) })
  //   }
  // },[]);

  const handleChange = (event, newValue, newName) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar color="default" className={classes.appBar}>
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          {props.tabs &&
            props.tabs.map((tab, i) => {
              return <Tab key={tab.label} label={tab.label} />;
            })}
        </Tabs>
      </AppBar>
      {props.tabs &&
        props.tabs.map((tab, i) => {
          return (
            <TabPanel key={i} value={value} index={i}>
              {tab.content}
            </TabPanel>
          );
        })}
    </div>
  );
}

export default StyledTabs;
