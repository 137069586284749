// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Store
import rootReducer from "reducers";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import {
  reduxFirestore,
  getFirestore,
  createFirestoreInstance
} from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"; // live
// import { composeWithDevTools } from "redux-devtools-extension"; // testing
import { getFirebase } from "react-redux-firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Init firebase instance
firebase.initializeApp(firebaseConfig);

// Init firebase auth
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Init firestore
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence({ synchronizeTabs: true });

// Storage Ref
export const storageRef = firebase.storage().ref();

// Init analytics
firebase.analytics();

// Create initial state
const initialState = {};

// Create middleware
const middleware = [
  thunk.withExtraArgument({ getFirebase, getFirestore })
  // This is where you add other middleware like redux-observable
];

// Create store
function createReduxStore() {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware),
      reduxFirestore(firebase, firebaseConfig)
    )
  );
}

const store = createReduxStore();
export default store;

// react-redux-firebase config
// https://react-redux-firebase.com/docs/recipes/profile.html#profile-in-firestore
const rrfConfig = {
  userProfile: null,
  // userProfile: "users",
  // attachAuthIsReady: true,
  useFirestoreForProfile: true
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};
