import React from "react";
import { Redirect } from "react-router-dom";
import {
  PublicRoute,
  PrivateRoute,
  SubscribedRoute,
  LoginForm,
  RegisterForm,
  NotFound,
  AccessDenied,
  SubscriberDenied,
  Help,
  EmailChangeForm,
  PasswordResetForm,
  NameChangeForm
} from "@austere-monorepo/components";

import Home from "client/home/Home";
import Map from "client/map/Map";

// Icons
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

// User
import MyAccount from "client/user/MyAccount";

// Notes
import AddNote from "client/notes/AddNote";
import EditNote from "client/notes/EditNote";
import NotesListTable from "client/notes/NotesListTable";
import ViewNote from "client/notes/ViewNote";

import Guidelines from "client/guidelines/Guidelines";

export const items = [
  {
    type: PublicRoute,
    path: "/404",
    component: NotFound
  },
  {
    type: PublicRoute,
    path: "/access-denied",
    component: AccessDenied
  },
  {
    type: PrivateRoute,
    path: "/subscriber-denied",
    component: SubscriberDenied
  },
  {
    type: PublicRoute,
    path: "/login",
    component: LoginForm
  },
  {
    type: PublicRoute,
    path: "/register",
    component: RegisterForm
  },
  {
    type: PrivateRoute,
    path: "/account",
    component: MyAccount
  },
  {
    type: PublicRoute,
    path: "/help",
    component: Help
  },
  {
    type: PrivateRoute,
    path: "/change-email",
    component: EmailChangeForm
  },
  {
    type: PrivateRoute,
    path: "/change-name",
    component: NameChangeForm
  },
  {
    type: PublicRoute,
    path: "/reset-password",
    component: PasswordResetForm
  },
  {
    type: PrivateRoute,
    path: "/",
    component: Home,
    name: "Home",
    icon: <HomeIcon />
  },
  {
    type: SubscribedRoute,
    role: 2,
    path: "/notes",
    component: NotesListTable,
    name: "N.O.T.E.S",
    icon: <LocalHospitalIcon />
  },
  {
    type: SubscribedRoute,
    role: 2,
    path: "/notes/add",
    component: AddNote
  },
  {
    type: SubscribedRoute,
    role: 2,
    path: "/notes/view/:id",
    component: ViewNote
  },
  {
    type: SubscribedRoute,
    role: 2,
    path: "/notes/edit/:id",
    component: EditNote
  },
  {
    type: SubscribedRoute,
    role: 2,
    path: "/map",
    component: Map,
    name: "Map",
    icon: <MapIcon />
  },
  { type: PublicRoute, path: "/guidelines/:path", component: Guidelines },
  {
    type: PublicRoute,
    name: "Guidelines",
    path: "/guidelines",
    component: () => <Redirect from="/guidelines" to="/guidelines/home" />,
    icon: <LibraryBooksIcon />
  },
  { component: () => <Redirect to="/404" /> }
];

// Filter routes to only include items with name for the sidebar
const filteredSidebarItems = items && items.filter((route) => route.name);

// Export routes to sidebar, remove unused items
export const sidebarItems =
  filteredSidebarItems &&
  filteredSidebarItems.map(({ component, type, ...keepAttrs }) => keepAttrs);
