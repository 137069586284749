import React from "react";
import { Field } from "redux-form";
import {
  RenderTextField,
  RenderSelectField,
  RenderFormSection
} from "@austere-monorepo/components";

const TotalBodyExam = () => (
  <RenderFormSection heading="Total Body Exam" id="totalbodyexam">
    <RenderFormSection heading="Pain Assessment" id="painassessment">
      <Field
        name="pain_onset"
        label="Pain onset"
        type="text"
        placeholder="Describe the onset of pain"
        component={RenderTextField}
        md={6}
      />
      <Field
        name="pain_provokes"
        label="Pain provokes"
        type="text"
        placeholder="Describe what provokes the pain"
        component={RenderTextField}
        md={6}
      />
      <Field
        name="pain_quality"
        label="Pain quality"
        type="text"
        placeholder="Describe the qualities of pain"
        component={RenderTextField}
        md={6}
      />
      <Field
        name="pain_radiates"
        label="Pain Radiates"
        placeholder="Is the pain local or does it radiate?"
        type="text"
        component={RenderTextField}
        md={6}
      />
      <Field
        name="pain_severity"
        label="Pain severity"
        placeholder="from 1 to 10"
        type="text"
        component={RenderSelectField}
        items={[
          { label: "1 - least severe", val: 1 },
          { label: "2", val: "2" },
          { label: "3", val: "3" },
          { label: "4", val: "4" },
          { label: "5", val: "5" },
          { label: "6", val: "6" },
          { label: "7", val: "7" },
          { label: "8", val: "9" },
          { label: "9", val: "9" },
          { label: "10 - most severe", val: "10" }
        ]}
        // helper={'Pain severity from 1 to 10'}
        md={4}
      />
      <Field
        name="pain_time"
        label="Pain time"
        placeholder="When did the pain start"
        type="text"
        component={RenderTextField}
        md={4}
      />
    </RenderFormSection>
    <RenderFormSection heading="Spinal Assessment" id="spinalassessment">
      <Field
        name="spinal_pain"
        label="Spinal Pain"
        component={RenderSelectField}
        items={[
          { label: "No", val: "no" },
          { label: "Yes", val: "yes" }
        ]}
        md={4}
      />
      <Field
        name="spinal_loc"
        label="Level of consciousness"
        component={RenderSelectField}
        items={[
          { label: "Normal", val: "normal" },
          { label: "Altered", val: "altered" }
        ]}
        md={4}
      />
      <Field
        name="spinal_csms"
        label="CSMS"
        component={RenderSelectField}
        items={[
          { label: "Normal", val: "normal" },
          { label: "Altered", val: "altered" }
        ]}
        md={4}
      />
      <Field
        name="spinal_vitals"
        label="Vital Signs"
        component={RenderSelectField}
        items={[
          { label: "Stable", val: "stable" },
          { label: "Unstable", val: "unstable" }
        ]}
        md={4}
      />
      <Field
        name="spinal_distraction"
        label="Distractions"
        component={RenderSelectField}
        items={[
          { label: "No", val: "no" },
          { label: "Yes", val: "yes" }
        ]}
        md={4}
      />
      <Field
        name="spinal_sober"
        label="Sober"
        component={RenderSelectField}
        items={[
          { label: "Yes", val: "yes" },
          { label: "No", val: "no" }
        ]}
        md={4}
      />
      <Field
        name="spinal_age"
        label="Age Risk"
        component={RenderSelectField}
        items={[
          { label: "< 55", val: "<55" },
          { label: "> 55", val: ">55" }
        ]}
        md={4}
      />
      <Field
        name="spinal_bone_disease"
        label="Bone Disease"
        component={RenderSelectField}
        items={[
          { label: "No", val: "no" },
          { label: "Yes", val: "yes" }
        ]}
        md={4}
      />
    </RenderFormSection>
    <RenderFormSection heading="Other" id="other">
      <Field
        name="blood_loss"
        label="Blood Loss"
        component={RenderSelectField}
        items={[
          { label: "Nil", val: "Nil" },
          { label: "< 500ml", val: "< 500ml" },
          { label: "> 500ml", val: "> 500ml" }
        ]}
        md={4}
      />
      <Field
        name="burns_tbsa"
        label="Burns % TBSA"
        type="number"
        component={RenderTextField}
        md={4}
      />
    </RenderFormSection>
  </RenderFormSection>
);

export default TotalBodyExam;
