import React, { Fragment } from "react";
// import ReactLeafletSearch from 'react-leaflet-search'

import { useMap, Tooltip, Circle, CircleMarker } from "react-leaflet";
import SearchIcon from "@material-ui/icons/Search";
import MapButton from "./MapButton";
import { withSnackbar } from "notistack";

function Search(props) {
  const map = useMap();

  return (
    <Fragment>
      <MapButton title={"Search"} order={4} fontSize='small'>
        <SearchIcon />
      </MapButton>
    </Fragment>
  );
}

export default withSnackbar(Search);
