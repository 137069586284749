import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { DashboardLayout, getRoleStatus } from "@austere-monorepo/components";
import { appTitle, appSubtitle, roles, version } from "config";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clientLogo from "assets/client-logo.svg";
import toolbarLogo from "assets/toolbar-logo.svg";
import backgroundImage from "assets/background.jpg";

// Icons
import PhoneIcon from "@material-ui/icons/Phone";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { sidebarItems } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: 1
  },
  button: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  clientLogoBox: {
    textAlign: "center",
    width: "100%",
    margin: theme.spacing(4, 0)
  },
  clientLogo: {
    height: "75px",
    textAlign: "center",
    width: "100%"
  }
}));

function Info() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Dialog style={{ padding: "20px" }} open={open} onClose={handleClose}>
        <DialogTitle disableTypography style={{ textAlign: "center" }}>
          {"Are you sure you want to call 000?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The 000 service should only be used in emergencies. If you press
            'Call 000', a call will be made from your phone to emergency
            services.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="outlined"
            component="a"
            href="tel:000"
            onClick={handleClose}
            startIcon={<PhoneIcon />}
            className={classes.button}
          >
            Call 000
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.root}>
        <Button
          className={classes.button}
          variant="outlined"
          fullWidth
          onClick={handleOpen}
          startIcon={<PhoneIcon />}
        >
          Call 000
        </Button>
        <Box className={classes.clientLogoBox}>
          <Box
            component={"img"}
            className={classes.clientLogo}
            src={clientLogo}
            alt="survive"
          />
          <Typography
            color="primary"
            align="center"
            variant="caption"
            style={{ width: "100%" }}
            component="a"
            href="https://www.survivefirstaid.com.au/"
          >
            survivefirstaid.com.au
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

function Dashboard({ _isAuth, _role, _user, children }) {
  const quickAddItems = [
    {
      title: "Add a N.O.T.E.S Form",
      path: "/notes/add",
      icon: <LocalHospitalIcon />
    }
  ];

  // Check the user's subscription each reload
  getRoleStatus();

  return (
    <DashboardLayout
      menuItems={sidebarItems}
      children={children}
      isAuth={_isAuth}
      user={_user}
      quickAddItems={quickAddItems}
      appTitle={appTitle}
      appSubtitle={appSubtitle}
      version={version}
      info={<Info />}
      role={_role && _role}
      roleTitle={roles[_role]}
      backgroundImage={backgroundImage}
      toolbarLogo={toolbarLogo}
    />
  );
}

const mapStateToProps = ({ firebase: { auth }, firestore: { data } }) => ({
  _user: auth,
  _profile: data && data.owner,
  _isAuth: auth.isLoaded && !auth.isEmpty,
  _role: data.owner && data.owner.role
});

export default compose(connect(mapStateToProps))(Dashboard);
