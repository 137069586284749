import React from "react";
import {
  RenderTextField,
  RenderSelectField,
  RenderFormSection
} from "@austere-monorepo/components";
import { Field } from "redux-form";
import moment from "moment";

const NeedToKnow = () => (
  <RenderFormSection heading={"Need to Know"}>
    <Field
      required
      name="date"
      label="Date/Time"
      type="datetime-local"
      component={RenderTextField}
      format={(value) => moment(value).format().slice(0, -9)}
      parse={(value) => new Date(value).getTime()}
      defaultValue={new Date().getTime()}
      md={6}
    />
    <Field
      name="name"
      label="Patient name"
      type="text"
      defaultValue="test"
      component={RenderTextField}
      md={6}
    />
    <Field
      name="dob"
      label="D.O.B"
      type="date"
      format={(value) => moment(value).format().slice(0, -15)}
      parse={(value) => new Date(value).getTime()}
      defaultValue={new Date("2014-08-18T21:11:54")}
      component={RenderTextField}
      md={4}
    />
    <Field
      name="gender"
      label="Patient gender"
      component={RenderSelectField}
      items={[
        { label: "Male", val: "Male" },
        { label: "Female", val: "Female" },
        { label: "Other", val: "Other" }
      ]}
      md={4}
    />
    <Field
      name="phone"
      label="Patient phone number"
      type="tel"
      component={RenderTextField}
      md={4}
    />
    <Field
      // multiple
      // format={(value) => (Array.isArray(value) ? value : [])}
      name="complaint_type"
      label="Complaint type(s)"
      component={RenderSelectField}
      items={[
        { label: "Injury", val: "Injury" },
        { label: "Illness", val: "Illness" },
        { label: "Environmental", val: "Environmental" }
      ]}
      md={4}
    />
    <Field
      name="description"
      label="Chief complaint"
      type="text"
      placeholder="Required. The main reason for seeking first-aid"
      required
      component={RenderTextField}
      md={8}
    />
    <Field
      name="moi_noi"
      label="MOI / NOI"
      type="text"
      multiline
      placeholder="Mechanism of injury/ nature of illness"
      component={RenderTextField}
    />
    <Field
      name="contact_person"
      label="Contact Person"
      type="text"
      placeholder="Contact person full name"
      component={RenderTextField}
      md={6}
    />
    <Field
      name="contact_phone"
      label="Contact Phone/Email"
      type="tel"
      placeholder="Contact person phone number or email"
      component={RenderTextField}
      md={6}
    />
  </RenderFormSection>
);

export default NeedToKnow;
