import React, { Fragment } from 'react';
import moment from 'moment';
import { NoData } from '@austere-monorepo/components';
import { Typography } from '@material-ui/core';

function RenderNotes({ notes }) {
  return (
    <Fragment>
      {notes &&
        notes.map((note, i) => (
          <Fragment key={i}>
            <Typography variant="caption" color="textSecondary">{`${
              note.created_by_name
            } ${moment(note.date).format('DD-MMM-YY HH:mm')}`}</Typography>
            <Typography gutterBottom>- {note.note}</Typography>
          </Fragment>
        ))}
      <NoData array={notes} />
    </Fragment>
  );
}

export default RenderNotes;
