import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // maxWidth: "100vw",
    minHeight: "calc(100vh - 56px)"
  },
  container: {
    minHeight: "calc(100vh - 56px)",
    padding: theme.spacing(2.5, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  }
}));

function StyledWrapper(props) {
  const { maxWidth } = props;
  const classes = useStyles();
  return (
    <Container
      id={props.id}
      className={classes.root}
      maxWidth={maxWidth ? maxWidth : false}
      disableGutters
    >
      <Container className={classes.container}>{props.children}</Container>
    </Container>
  );
}

export default StyledWrapper;
