import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TocButton } from "@austere-monorepo/components";
import { Drawer, Hidden, Box, Typography } from "@material-ui/core";
import { GoBackButton } from "@austere-monorepo/components";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "block" // needed to keep appBar sticky on ios
    // backgroundColor: theme.palette.background.paper
  },
  appBar: {
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25, 1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.appBar - 1,
    top: "56px",
    width: "calc(100vw - 200px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  appBarContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  appBarContentBox: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem"
    }
  },
  drawer: {
    // margin: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0),
    "& .MuiDrawer-paperAnchorLeft": {
      left: "auto"
    },
    "& .MuiDrawer-paper": {
      top: "auto"
      // padding: theme.spacing(1)
      // margin: theme.spacing(2.5, 2),
      // border: `1px solid ${theme.palette.divider}`
    },
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },

  drawerContainer: {
    height: "100%",
    overflow: "auto",
    width: drawerWidth,
    // padding: theme.spacing(0),
    /* Hide scrollbar for IE, Edge and Firefox */
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    /* Hide scrollbar for Chrome, Safari and Opera */
    "&::-webkit-scrollbar": {
      display: "none"
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 102px)"
    }
  },
  content: {
    marginTop: "45px", // appBar height
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth
    }
  },
  contentNoSidebar: {
    marginLeft: 0
  }
}));

function StyledSideView({ title, sidebar, content, actionBar }) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Box className={classes.root}>
        {actionBar && (
          <Box className={classes.appBar}>
            <Box className={classes.appBarContent}>
              {/* Left Box */}
              <Box className={classes.appBarContentBox}>
                <GoBackButton />
                {title && (
                  <Typography className={classes.title}>{title}</Typography>
                )}
              </Box>
              {/* Center Box */}
              {/* <Box className={classes.appBarContentBox}>
               
              </Box> */}
              {/* Right Box */}
              <Box className={classes.appBarContentBox}>
                {actionBar}
                <Hidden smUp implementation="css">
                  <TocButton onClick={handleDrawerToggle} />
                </Hidden>
              </Box>
            </Box>
          </Box>
        )}
        {sidebar && (
          <Fragment>
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                onClick={handleDrawerToggle}
              >
                <div className={classes.drawerContainer}>{sidebar}</div>
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Box className={classes.drawer}>
                <Drawer
                  classes={{ paper: classes.drawerPaper }}
                  variant="permanent"
                  open
                >
                  <div className={classes.drawerContainer}>{sidebar}</div>
                </Drawer>
              </Box>
            </Hidden>
          </Fragment>
        )}
        <Box
          className={
            sidebar !== null ? classes.content : classes.contentNoSidebar
          }
        >
          {content}
        </Box>
      </Box>
    </div>
  );
}

export default StyledSideView;
