import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ImageOverlay,
  LayerGroup,
  LayersControl,
  TileLayer,
  GeoJSON
} from "react-leaflet";
import L from "leaflet";
import {
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import MapButton from "./MapButton";
import Marker from "./Marker";

// Markers

// const items = {
//   "Patient (complete)": { stroke: "red", fill: "none" },
//   "Patient (incomplete)": { stroke: "red", fill: "red" },
//   Tasks: { stroke: "blue", fill: "none" },
//   // 'Task (incomplete)': { stroke: 'blue', fill: 'blue' },
//   General: { stroke: "steelblue", fill: "steelblue" },
//   Clinic: { stroke: "indigo", fill: "indigo" },
//   Hospital: { stroke: "purple", fill: "purple" },
//   "Medical Supplies": { stroke: "mediumvioletred", fill: "mediumvioletred" },
//   Accommodation: { stroke: "peru", fill: "peru" },
//   Food: { stroke: "lightsalmon", fill: "lightsalmon" },
//   Fuel: { stroke: "slateblue", fill: "slateblue" },
//   Airport: { stroke: "green", fill: "green" },
//   "Helicopter LZ": { stroke: "palegreen", fill: "palegreen" },
//   Hazard: { stroke: "red", fill: "yellow" }
// };

import taskMarker from "./markers/task.png";
import poiMarker from "./markers/poi.png";
import hazardMarker from "./markers/hazard.png";
import patientMarker from "./markers/patient.png";

const { BaseLayer, Overlay } = LayersControl;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiList-padding": {
      padding: theme.spacing(1)
    }
  },
  formControl: {
    margin: theme.spacing(1)
  },
  formControlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.85rem"
    }
  },
  offlineCount: {
    marginTop: "-9px",
    marginLeft: "28px",
    fontSize: 10
  }
}));

// https://www.w3schools.com/colors/colors_names.asp

function Layers(props) {
  const {
    baseLayers,
    offlineLayers,
    imageBaseLayers,
    dataLayers,
    geoJsonLayers,
    initBaseLayer,
    initDataLayers
  } = props;

  // console.log(initDataLayers)

  const classes = useStyles();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markers = {
    poi: poiMarker,
    task: taskMarker,
    hazard: hazardMarker,
    access: poiMarker,
    patient: patientMarker
  };

  // Map base layer state
  const [baseValue, setBaseValue] = useState(initBaseLayer);
  // console.log(initBaseLayer);
  const handleBaseChange = (event) => {
    setBaseValue(event.target.value);
    dispatch({
      type: "UPDATE_MAP_BASE",
      payload: {
        base: event.target.value
      }
    });
    // console.log("baseValue", baseValue);
  };

  // Map data layer state
  const [dataValue, setDataValue] = useState(initDataLayers);

  // console.log("initdataValue", dataValue);

  const handleDataChange = (event) => {
    setDataValue({ ...dataValue, [event.target.name]: event.target.checked });
    dispatch({
      type: "UPDATE_DATA_LAYERS",
      payload: {
        data: dataValue
      }
    });
  };

  const getCacheLength = () => {
    offlineLayers.map(function (obj) {
      caches.open(obj.cacheName).then(function (cache) {
        cache.keys().then(function (keys) {
          return Object.assign(obj, {
            downloadedLength: keys.length
          });
        });
      });
    });
    return offlineLayers;
  };

  const offlineLayersWithCache = offlineLayers && getCacheLength();

  return (
    <Fragment>
      <MapButton title={"Map layers"} order={0} onClick={handleClick}>
        <LayersIcon fontSize="small" />
      </MapButton>
      <Dialog open={Boolean(anchorEl)} onClose={handleClose}>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <FormLabel>Base Layers</FormLabel>
            <RadioGroup value={baseValue} onChange={handleBaseChange}>
              {baseLayers &&
                baseLayers.map((layer, i) => (
                  <FormControlLabel
                    className={classes.formControlLabel}
                    key={i}
                    size="small"
                    value={layer.title}
                    control={<Radio size="small" />}
                    label={layer.title}
                  />
                ))}
            </RadioGroup>
          </FormControl>

          {offlineLayers && (
            <FormControl className={classes.formControl}>
              <FormLabel>Offline Layers</FormLabel>
              <RadioGroup value={baseValue} onChange={handleBaseChange}>
                {offlineLayersWithCache &&
                  offlineLayersWithCache.map((layer, i) => {
                    return (
                      <Fragment>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          key={i}
                          value={layer.title}
                          control={<Radio size="small" />}
                          label={layer.title}
                        />
                        <FormHelperText className={classes.offlineCount}>
                          {`Downloaded: ${
                            layer.downloadedLength === undefined
                              ? "?"
                              : layer.downloadedLength
                          }/${layer.cacheLength} tiles`}
                        </FormHelperText>
                      </Fragment>
                    );
                  })}
              </RadioGroup>
            </FormControl>
          )}

          {imageBaseLayers && (
            <FormControl className={classes.formControl}>
              <FormLabel>Image Layers</FormLabel>
              <RadioGroup value={baseValue} onChange={handleBaseChange}>
                {imageBaseLayers &&
                  imageBaseLayers.map((layer, i) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={i}
                      value={layer.title}
                      control={<Radio size="small" />}
                      label={layer.title}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          )}

          {dataLayers && (
            <FormControl className={classes.formControl}>
              <FormLabel>Data Layers</FormLabel>
              <FormGroup>
                {dataLayers &&
                  dataLayers.map((layer, i) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={i}
                      control={
                        <Checkbox
                          size="small"
                          checked={dataValue[`${layer.title}`]}
                          onChange={handleDataChange}
                          name={layer.title}
                        />
                      }
                      label={layer.title}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          )}

          {geoJsonLayers && (
            <FormControl className={classes.formControl}>
              <FormLabel>Other Layers</FormLabel>
              <FormGroup>
                {geoJsonLayers &&
                  geoJsonLayers.map((layer, i) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={i}
                      control={
                        <Checkbox
                          size="small"
                          checked={dataValue[`${layer.title}`]}
                          onChange={handleDataChange}
                          name={layer.title}
                        />
                      }
                      label={layer.title}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <LayersControl>
        {baseLayers &&
          baseLayers.map((layer, i) => (
            <BaseLayer
              key={i}
              checked={baseValue === layer.title ? true : false}
              name={layer.title}
            >
              <TileLayer
                attribution={false}
                url={layer.url}
                maxZoom={layer.maxZoom ? layer.maxZoom : 18}
                minZoom={layer.minZoom ? layer.minZoom : 1}
              />
            </BaseLayer>
          ))}

        {imageBaseLayers &&
          imageBaseLayers.map((layer, i) => (
            <BaseLayer
              key={i}
              checked={baseValue === layer.title ? true : false}
              name={layer.title}
            >
              <ImageOverlay bounds={layer.bounds} url={layer.url} />
            </BaseLayer>
          ))}

        {offlineLayers &&
          offlineLayers.map((layer, i) => (
            <BaseLayer
              key={i}
              checked={baseValue === layer.title ? true : false}
              name={layer.title}
            >
              <TileLayer
                attribution={false}
                // bounds={layer.bounds ? layer.bounds : null}
                maxZoom={layer.maxZoom ? layer.maxZoom : 18}
                maxNativeZoom={layer.maxNativeZoom ? layer.maxNativeZoom : 16}
                minZoom={layer.minZoom ? layer.minZoom : 1}
                url={layer.url}
              />
            </BaseLayer>
          ))}

        {dataLayers &&
          dataLayers.map((layer, i) => (
            <Overlay
              key={i}
              checked={dataValue[layer.title]}
              name={layer.title}
            >
              <LayerGroup>
                {layer.data &&
                  layer.data.map((point, i) => (
                    <Marker
                      key={i}
                      position={[point.latitude, point.longitude]}
                      title={layer.title}
                      description={point.description}
                      status={point.status}
                      type={layer.type}
                      collection={layer.collection}
                      id={point.id}
                      marker={markers[layer.type]}
                    />
                  ))}
              </LayerGroup>
            </Overlay>
          ))}

        {geoJsonLayers &&
          geoJsonLayers.map((layer, i) => (
            <Overlay
              key={i}
              checked={dataValue[layer.title]}
              name={layer.title}
            >
              <LayerGroup>
                <GeoJSON
                  data={layer.data}
                  style={function (feature) {
                    return {
                      // fillColor: feature.properties.fill
                      //   ? feature.properties.fill
                      //   : "red", // defaults to color
                      color: feature.properties.color
                        ? feature.properties.color
                        : "purple", // stroke color

                      // weight: 0.3,
                      // //stroke-width: to have a constant width on the screen need to adapt with scale
                      opacity: 1,
                      // color: material_design_colors[this.state.polygonFillColor]["400"],
                      dashArray: feature.properties.dash,
                      fillOpacity: 0.2
                    };
                  }}
                  pointToLayer={function (feature, latlng) {
                    return L.marker(latlng, {
                      // icon: markers[feature.properties.category],
                      icon: L.icon({
                        iconUrl: feature.properties.category
                          ? markers[feature.properties.category]
                          : poiMarker,
                        iconSize: [30, 30], // pixels
                        iconAnchor: [13, 32]
                      })
                    }).bindTooltip(String(feature.properties.name), {
                      permanent: true
                    });
                  }}
                />
              </LayerGroup>
            </Overlay>
          ))}
      </LayersControl>
    </Fragment>
  );
}

export default Layers;
