import React, { Fragment } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  listItemGutters: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  iconRoot: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 2, 0, 1),
    minWidth: 0
  }
}));

function Sidebar({ items, role }) {
  const classes = useStyles();
  const location = useLocation();
  const userRole = role ? role : 1;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <List dense={desktop ? true : false} disablePadding>
      {items &&
        items.map((item, i) => {
          const itemRole = item.role ? item.role : 1;
          return (
            <Fragment key={i}>
              {userRole >= itemRole && (
                <ListItem
                  button
                  classes={{ gutters: classes.listItemGutters }}
                  component={item.path ? NavLink : "li"}
                  selected={
                    location.pathname.split("/")[1] === item.path.split("/")[1]
                  }
                  to={item.path}
                >
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={item.name}
                  />
                </ListItem>
              )}
            </Fragment>
          );
        })}
    </List>
  );
}

export default Sidebar;
