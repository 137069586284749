import {
  FormHelperText,
  Select,
  MenuItem,
  ListSubheader,
  Divider
} from "@material-ui/core";
import FieldWrapper from "./FieldWrapper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    // backgroundColor: theme.palette.background.default,
  },
  notCurrent: {
    color: theme.palette.text.secondary
  }
}));

export function RenderSelectCurrencyField({
  xs,
  md,
  input,
  label,
  name,
  helper,
  touched,
  required,
  currentItems,
  nonCurrentItems,
  ...rest
}) {
  const classes = useStyles();

  return (
    <FieldWrapper
      label={label}
      required={required}
      name={name}
      helper={helper}
      md={md}
      xs={xs}
    >
      <Select
        className={classes.field}
        variant="outlined"
        labelId={label}
        {...input}
        {...rest}
        name={name}
        inputProps={{
          name: `${name}`,
          id: `${name}`
        }}
      >
        <ListSubheader>Current</ListSubheader>
        {currentItems &&
          currentItems.map((item, i) => (
            <MenuItem key={i} value={item.currency}>
              {item.currency}
            </MenuItem>
          ))}
        <Divider />
        <ListSubheader>Not Current</ListSubheader>
        {nonCurrentItems &&
          nonCurrentItems.map((item, i) => (
            <MenuItem key={i} value={item.currency}>
              {item.currency}
            </MenuItem>
          ))}
      </Select>

      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FieldWrapper>
  );
}
