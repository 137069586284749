import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment/moment";
import {
  StyledProgress,
  MiniMap,
  StyledViewSection,
  ImageBlock
} from "@austere-monorepo/components";

function ViewNote({ note }) {
  const rows = note
    ? [
        {
          heading: "Need To Know",
          items: [
            {
              heading: "Date",
              value: moment(note.date).format("DD-MMM-YYYY HH:mm"),
              md: 4
            },
            {
              heading: "Patient Name",
              value: note.name,
              md: 4
            },
            {
              heading: "DOB",
              value: note.dob ? moment(note.dob).format("DD-MMM-YYYY") : "-",
              md: 4
            },
            {
              heading: "Age",
              value: `${moment().diff(note.dob, "y")}yo`,
              md: 4
            },
            {
              heading: "Gender",
              value: note.gender,
              md: 4
            },
            {
              heading: "Patient Phone",
              value: note.phone,
              md: 4
            },
            {
              heading: "Complaint Type",
              value: note.complaint_type,
              md: 4
            },
            {
              heading: "Chief Complaint",
              value: note.description,
              md: 8
            },
            {
              heading: "MOI/NOI",
              value: note.moi_noi
            },
            {
              heading: "Patient Contact/NOK",
              value: `${note.contact_person} ${note.contact_phone}`
            }
          ]
        },
        {
          heading: "Observations",
          items:
            note.obs &&
            note.obs.map((ob) => ({
              heading: ob.time,
              value: `LOC ${ob.loc}. Heart ${ob.hr}/min ${ob.hr_rhythm} ${ob.hr_quality}. Breathing ${ob.rr}/min ${ob.rr_rhythm} ${ob.rr_quality}. Skin ${ob.skin_color} ${ob.skin_temp} ${ob.skin_moisture}. Pupils ${ob.pupils_symmetry} ${ob.pupils_reactivity} ${ob.pupils_diameter}`
            }))
        },
        {
          heading: "Total Body Exam",
          items: [
            { heading: "Blood Loss", value: note.blood_loss, md: 4 },
            { heading: "Burns TBSA", value: `${note.burns_tbsa}%`, md: 4 },
            { subHeading: "Pain Assessment (OPQRST)" },
            { heading: "Pain Onset", value: note.pain_onset, md: 4 },
            { heading: "Pain Provokes", value: note.pain_provokes, md: 4 },
            { heading: "Pain Quality", value: note.pain_quality, md: 4 },
            { heading: "Pain Radiates", value: note.pain_radiates, md: 4 },
            {
              heading: "Pain Severity",
              value: `${note.pain_severity}/10`,
              md: 4
            },
            { heading: "Pain Time", value: note.pain_time, md: 4 },
            { subHeading: "Spinal Risk Assessment" },
            { heading: "Spinal Pain", value: note.spinal_pain, md: 4 },
            { heading: "Vital Signs", value: note.spinal_vitals, md: 4 },
            { heading: "Spinal LOC", value: note.spinal_loc, md: 4 },
            { heading: "CSMS", value: note.spinal_csms, md: 4 },
            { heading: "Sober", value: note.spinal_sober, md: 4 },
            { heading: "Age Risk", value: note.spinal_age, md: 4 },
            { heading: "Bone Disease", value: note.spinal_bone_disease, md: 4 },
            {
              heading: "Distracting Injuries",
              value: note.spinal_distraction,
              md: 4
            }
          ]
        },
        {
          heading: "Enquire",
          items: [
            { heading: "Signs & Symptoms", value: note.symptoms },
            { heading: "Allergies", value: note.allergies },
            { heading: "Medication", value: note.medication },
            { heading: "Past History", value: note.past_history },
            { heading: "Last In and Out", value: note.last_inout },
            { heading: "Events Prior", value: note.events }
          ]
        },
        {
          heading: "Summary",
          items: [
            { heading: "EMS Called", value: note.ems_called, md: 4 },
            { heading: "EMS On-scene", value: note.ems_arrived, md: 4 },
            { heading: "EMS Departed", value: note.ems_departed, md: 4 },
            { heading: "Incident Description", value: note.incident },
            { heading: "Treatment Provided", value: note.treatment },
            { heading: "Scene Hazards", value: note.hazards },
            { heading: "Events Prior", value: note.events }
          ]
        },
        {
          heading: "Location",
          items:
            note.latitude && note.longitude
              ? [
                  {
                    heading: "Map",
                    value: (
                      <MiniMap
                        circle
                        id={note.id}
                        lat={note.latitude}
                        lng={note.longitude}
                      />
                    )
                  },
                  {
                    heading: "Google Maps",
                    value: `${note.latitude},${note.longitude}`,
                    link: `https://www.google.com/maps/search/?api=1&query=${note.latitude},${note.longitude}`,
                    md: 6
                  },
                  {
                    heading: "GPS Accuracy",
                    value:
                      note.latitude && note.longitude
                        ? note.accuracy
                          ? note.accuracy
                          : "N/A Plotted"
                        : null,
                    md: 6
                  }
                ]
              : [{ heading: "N/A", value: "No Location" }]
        },
        {
          heading: "Images",
          items: note.images
            ? note.images.map((image, i) => ({
                heading: `Image ${i + 1}`,
                value: <ImageBlock key={i} url={image.url} note={image.note} />,
                md: 6
              }))
            : [{ heading: "N/A", value: "No Images" }]
        },
        {
          heading: "Handover",
          items: [
            {
              heading: "Identify the Patient",
              value: `${note.name} ${moment().diff(note.dob, "y")}yo ${
                note.gender
              }`
            },
            { heading: "MOI/NOI", value: note.moi_noi },
            {
              heading: "Injury/Illness",
              value: `${note.complaint_type} ${note.description}`
            },
            { heading: "Signs and Symptoms", value: note.symptoms },
            { heading: "Treatment Provided", value: note.treatment },
            { heading: "Allergies", value: note.allergies },
            { heading: "Medication", value: note.medication },
            {
              heading: "Background",
              value: `${note.past_history} ${note.events}`
            },
            { heading: "Other Information", value: note.other_info }
          ]
        }
      ]
    : [note];
  return (
    <Fragment>
      {note && (
        <Fragment>
          <StyledViewSection
            title="N.O.T.E.S"
            rows={rows}
            collection={"notes"}
            doc={"note"}
            id={note.id}
            editRole={2}
          />
        </Fragment>
      )}
      {!note && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps({ firestore: { ordered } }, props) {
  return {
    note:
      ordered.notes &&
      ordered.notes.filter((note) => note.id === props.match.params.id)[0]
  };
}

export default compose(connect(mapStateToProps))(ViewNote);
