import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { StyledTable } from "@austere-monorepo/components";

function NoteListTable({ _notes }) {
  const columns = [
    { title: "Edit", name: "edit" },
    { title: "View", name: "view" },
    { title: "Date", name: "date" },
    { title: "Complaint", name: "description" },
    { title: "Type", name: "complaint_type" },
    { title: "Age", name: "dob" },
    { title: "Gender", name: "gender" }
  ];

  const tableColumns = [
    { columnName: "date", width: 150 },
    { columnName: "description", width: 220 },
    { columnName: "complaint_type", width: 150 },
    { columnName: "dob", width: 120 }
  ];

  const dateTimeColumns = ["date"];

  const ageColumns = ["dob"];

  return (
    <StyledTable
      rows={_notes && _notes}
      columns={columns && columns}
      addButton
      collection={"notes"}
      doc={"note"}
      tableColumns={tableColumns}
      dateTimeColumns={dateTimeColumns}
      ageColumns={ageColumns}
    />
  );
}

const mapStateToProps = ({
  firebase: { auth },
  firestore: { data, ordered }
}) => ({
  _notes: ordered.notes && ordered.notes,
  _user: auth,
  _profile: data && data.user
});

export default compose(connect(mapStateToProps))(NoteListTable);
