import { getFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import {
  RenderTextField,
  StyledWrapper,
  validate
} from "@austere-monorepo/components";
import { Field } from "redux-form";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

const handleResetPassword =
  ({ email }) =>
  async (dispatch) => {
    const firebase = getFirebase();
    console.log(email);
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      await dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Password reset email Sent to ${email}`,
          options: {
            variant: "success"
          }
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Error resetting password ` + error,
          options: {
            variant: "error"
          }
        }
      });
    }
  };

function PasswordResetForm(props) {
  const { handleResetPassword, handleSubmit, submitting, error } = props;
  return (
    <form onSubmit={handleSubmit(handleResetPassword)}>
      <StyledWrapper maxWidth={"sm"}>
        <Card>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h2"
            align="center"
          >
            Reset Password
          </Typography>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12}>
              <Typography>
                You will be sent a password reset link to the email address
                associated with your account
              </Typography>
            </Grid>
            <Field
              name="email"
              label="Email"
              type="email"
              placeholder="Email Address"
              component={RenderTextField}
            />

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                label="register"
                type="submit"
                disabled={submitting || error}
              >
                Send Password Reset Link
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography component={Link} to={"/login"}>
                  Back to Login
                </Typography>

                <Typography align="center">
                  Issues?{" "}
                  <Typography
                    component="a"
                    target="_blank"
                    href="https://austererisk.com/support"
                  >
                    austererisk.com/support
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </StyledWrapper>
    </form>
  );
}

// const initialValues = {
//   password: '*************'
// };

export default compose(
  connect(null, { handleResetPassword }),
  reduxForm({
    form: "passwordReset",
    // handleResetPassword,
    validate
    // initialValues
  })
)(PasswordResetForm);
