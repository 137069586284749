import React from "react";
import { Grid } from "@material-ui/core";
import {
  ThemeSettings,
  AppInstalled,
  ShareThisApp,
  StyledWrapper
} from "@austere-monorepo/components";
import qrCode from "assets/qr-code.svg";
import { appUrl, appTitle } from "config";

function Settings() {
  return (
    <StyledWrapper>
      <Grid container spacing={2}>
        <ThemeSettings />
        <AppInstalled />
        <ShareThisApp appUrl={appUrl} qrCode={qrCode} appTitle={appTitle} />
      </Grid>
    </StyledWrapper>
  );
}

export default Settings;
