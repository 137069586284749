import { Link } from "react-router-dom";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import MapButton from "./MapButton";

function AddPoint() {
  return (
    <MapButton title={"Add geopoint"} component={Link} to={"geopoints/add"} order={4}>
      <AddLocationIcon fontSize='small' />
    </MapButton>
  );
}

export default AddPoint;
