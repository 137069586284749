import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Badge,
  Tooltip,
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: 6,
      top: 1
      // border: `1px solid ${theme.palette.common.white}`
      // padding: "0 4px"
    },
    "& .MuiBadge-dot": {
      height: "10px",
      padding: 5,
      minWidth: "10px",
      borderRadius: "5px"
    }
  },
  tab: {
    minWidth: "0px",
    padding: theme.spacing(0, 2),
    fontWeight: 400
  },
  heading: {
    fontWeight: 500
  },
  headingHidden: {
    color: theme.palette.text.secondary
  },
  details: {
    display: "inherit",
    padding: theme.spacing(0, 2)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function InstallPrompt() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(false);

  useEffect(() => {
    setInstallPrompt(
      localStorage.getItem("installPrompt") === "true" ? true : false
    );
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDismiss = () => {
    setInstallPrompt(false);
    localStorage.setItem("installPrompt", false);
    handleClose();
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      {installPrompt && (
        <Tooltip title="Install app">
          <IconButton color="inherit" onClick={handleOpen}>
            <Badge variant="dot" color="error" className={classes.badge}>
              <SystemUpdateAltIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      )}

      <Dialog style={{ padding: "20px" }} open={open} onClose={handleClose}>
        <DialogTitle disableTypography style={{ textAlign: "center" }}>
          {`Install App?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This application can be installed to your device. Follow the
            instructions below for help installing this app.
          </DialogContentText>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={
                  expanded === "panel1"
                    ? classes.heading
                    : classes.headingHidden
                }
              >
                iOS Devices
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography gutterBottom variant="body2">
                From Safari:
              </Typography>
              <Typography gutterBottom variant="body2">
                1. Click the menu button below on iPhone, or top-right on iPad
              </Typography>
              <Typography gutterBottom variant="body2">
                2. Click 'Add to Home Screen'
              </Typography>
              <Typography gutterBottom variant="body2">
                3. Confirm and install
              </Typography>
              <Typography
                variant="body2"
                component={"a"}
                href={
                  "https://austererisk.com/knowledge-base/field-apps/installation/ios-installation/"
                }
              >
                iOS Device Installation Tutorial
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={
                  expanded === "panel2"
                    ? classes.heading
                    : classes.headingHidden
                }
              >
                MacOS Devices
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography gutterBottom variant="body2">
                1. Open the app in Chrome or Microsoft Edge
              </Typography>
              <Typography gutterBottom variant="body2">
                2. Wait for the install prompt, or click 'Install' in the
                browser settings
              </Typography>
              <Typography gutterBottom variant="body2">
                3. Confirm and install
              </Typography>
              <Typography
                variant="body2"
                component={"a"}
                href={
                  "https://austererisk.com/knowledge-base/field-apps/installation/macos-installation/"
                }
              >
                MacOS Device Installation Tutorial
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={
                  expanded === "panel3"
                    ? classes.heading
                    : classes.headingHidden
                }
              >
                Android Devices
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography
                variant="body2"
                component={"a"}
                href={
                  "https://austererisk.com/knowledge-base/field-apps/installation/android-installation/"
                }
              >
                Android Device Installation Tutorial
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={
                  expanded === "panel4"
                    ? classes.heading
                    : classes.headingHidden
                }
              >
                Windows Devices
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography
                variant="body2"
                component={"a"}
                href={
                  "https://austererisk.com/knowledge-base/field-apps/installation/windows-installation/"
                }
              >
                Windows Device Installation Tutorial
              </Typography>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismiss}>Dismiss</Button>
          {/* <Button
            size="small"
            variant="outlined"
            onClick={updateServiceWorker}
            startIcon={<SystemUpdateAltIcon />}
            className={classes.button}
          >
            Install
          </Button> */}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default InstallPrompt;
