import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(4.5)
    }
  },
  text: {
    margin: theme.spacing(0),
    "& .MuiListItemText-secondary": {
      fontSize: "0.65rem"
    }
  }
}));

function StyledListLink(props) {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.root}
      button={props.to ? true : false}
      dense
      disableGutters
      component={props.to ? Link : null}
      to={props.to}
      key={props.primary}
      onClick={props.onClick}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        className={classes.text}
        primary={props.primary}
        primaryTypographyProps={{ noWrap: true }}
        secondary={props.secondary && props.secondary}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  );
}

export default StyledListLink;
