import cuid from "cuid";
import { getFirestore } from "redux-firestore";

export const handleFormAdd = ({ auth, formCollection, formData }) => {
  const firestore = getFirestore();
  return async (dispatch) => {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Saving form",
        options: {
          variant: "info"
        }
      }
    });
    try {
      const docID = cuid();
      await firestore.disableNetwork().then(() => {
        firestore.set(
          { collection: formCollection, doc: docID },
          {
            ...formData,
            created_at: Date.now(),
            created_by: auth.uid,
            created_by_name: auth.displayName,
            id: docID
          },
          { merge: true }
        );
      });
      await firestore.enableNetwork().then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: "Form saved",
            options: {
              variant: "success"
            }
          }
        });
      });
    } catch (e) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Form save failed" + e,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
};

export const handleFormEdit = ({
  auth,
  formCollection,
  formData,
  currentData
}) => {
  const firestore = getFirestore();
  return async (dispatch) => {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Updating form",
        options: {
          variant: "info"
        }
      }
    });
    try {
      await firestore.disableNetwork().then(() => {
        firestore.update(
          { collection: formCollection, doc: currentData.id },
          {
            ...formData,
            updated_at: new Date().getTime(),
            updated_by: auth.uid,
            updated_by_name: auth.displayName
          }
        );
      });
      await firestore.enableNetwork().then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: "Form updated",
            options: {
              variant: "success"
            }
          }
        });
      });
    } catch (e) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Form update failed " + e.message,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
};

export const handleFormDelete = ({ formCollection, formData }) => {
  const firestore = getFirestore();
  return async (dispatch) => {
    dispatch({
      type: "ENQUEUE_SNACKBAR",
      notification: {
        key: new Date().getTime() + Math.random(),
        message: "Deleting form",
        options: {
          variant: "info"
        }
      }
    });
    try {
      await firestore.disableNetwork().then(() => {
        firestore.delete({
          collection: formCollection,
          doc: formData.id
        });
      });
      await firestore.enableNetwork().then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: "Form deleted",
            options: {
              variant: "warning"
            }
          }
        });
      });
    } catch (e) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Form delete failed " + e.message,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
};
