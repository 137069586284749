import { isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { StyledProgress } from "@austere-monorepo/components";
import { Redirect } from "react-router-dom";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <StyledProgress />;
  return children;
}

function UserIsSignedIn({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (isEmpty(auth)) return <Redirect to="/login" />;
  return children;
}

function UserHasAccess({ role, children }) {
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  if (role > _role) return <Redirect to="/access-denied" />;
  return children;
}

function UserHasSubscription({ role, children }) {
  const _role = useSelector(
    (state) => state.firestore.data.owner && state.firestore.data.owner.role
  );
  if (role > _role) return <Redirect to="/subscriber-denied" />;
  return children;
}

export const PublicRoute = ({ children }) => {
  return children;
};

export const PrivateRoute = ({ children }) => {
  return (
    <AuthIsLoaded>
      <UserIsSignedIn>{children}</UserIsSignedIn>
    </AuthIsLoaded>
  );
};

export const SubscribedRoute = ({ role, children }) => {
  return (
    <AuthIsLoaded>
      <UserIsSignedIn>
        <UserHasSubscription role={role}>{children}</UserHasSubscription>
      </UserIsSignedIn>
    </AuthIsLoaded>
  );
};

export const RestrictedRoute = ({ role, children }) => {
  return (
    <AuthIsLoaded>
      <UserIsSignedIn>
        <UserHasAccess role={role}>{children}</UserHasAccess>
      </UserIsSignedIn>
    </AuthIsLoaded>
  );
};
