import { useHistory } from "react-router-dom";
import MapButton from "./MapButton";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";

function ViewPoints(props) {
  const history = useHistory();
  return (
    <MapButton title='View geopoints' onClick={() => history.push("/geopoints")} order={5}>
      <PageviewOutlinedIcon />
    </MapButton>
  );
}

export default ViewPoints;
