import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  progress: {
    position: "fixed",
    top: "50%",
    color: theme.palette.grey[400]
  },
  text: {
    position: "fixed",
    top: "calc(50% + 65px)",
    color: theme.palette.grey[400],
    fontSize: "0.9rem"
  }
}));

export function StyledProgress({ message }) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress className={classes.progress} size={50} />
      <Typography className={classes.text}>
        {message ? message : "Loading"}
      </Typography>
    </Backdrop>
  );
}

StyledProgress.propTypes = {
  message: PropTypes.string
};
