// App details
export const version = "1.0";
export const appTitle = "Survive App";
export const appSubtitle = "Wilderness First Aid";
export const appUrl = "https://survive.austererisk.com/";
export const forum = "https://austererisk.com/forum/survive-app";

// Roles
export const roles = {
  1: "Free Plan",
  2: "Premium Plan"
};

// Colors
export const primaryColor = "#56860d";

// Map
export const defaultLat = 0.02747;
export const defaultLng = 119.79012;
export const defaultZoom = 3;
export const defaultMapBase = "Base map";
export const defaultMapData = {
  "N.O.T.E.S.": true
};

// Store items and collections
export const storeItems = (props) => [
  {
    collection: "users",
    doc: `${props._user.uid}`,
    subcollections: [
      {
        collection: "notes"
      }
    ],
    storeAs: "notes"
  }
];

// Stripe
export const stripePublishKey = "pk_live_HCx7PcKTIFAlEb9jbMSnaFgr009Wg2kFbi";
export const functionsLocation = "australia-southeast1";
export const products = {
  id: "prod_ILTqyFVpSkv7Qx",
  headline: "Upgrade to access premium features",
  prices: [
    {
      title: "Yearly",
      price: "A$14.95",
      id: "price_1JCMG6AK1Ewchha4Iv5sTGQ8"
    },
    {
      title: "Monthly",
      price: "A$1.95",
      id: "price_1JCLULAK1Ewchha4RB68dZQX"
    }
  ]
};
