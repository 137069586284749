import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 0,
    padding: theme.spacing(0.5)
  },
  text: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.primary
  }
}));

function SimpleDialog(props) {
  const classes = useStyles();

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle disableTypography style={{ textAlign: "center" }}>
        Add Item
      </DialogTitle>
      <DialogContent>
        <List disablePadding dense>
          {props.items &&
            props.items.map((item, i) => {
              const itemRole = item.role ? item.role : 1;
              return (
                <Fragment key={i}>
                  {props.role >= itemRole && (
                    <ListItem
                      button
                      // key={i}
                      component={Link}
                      to={item.path}
                      onClick={() => handleListItemClick()}
                    >
                      <ListItemIcon className={classes.icon}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.icon}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={classes.text}
                        primary={item.title}
                      />
                    </ListItem>
                  )}
                </Fragment>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

function QuickAdd(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Tooltip title={"Add items"}>
        <IconButton color="inherit" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        role={props.role}
        items={props.quickAddItems}
      />
    </Fragment>
  );
}

export default QuickAdd;
