import React, { Fragment, useMemo } from "react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Notifier, createMasterTheme } from "@austere-monorepo/components";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { compose } from "redux";
import { useFirestoreConnect } from "react-redux-firebase";

// Fonts
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";

export const history = createBrowserHistory();

function App(props) {
  const { primaryColor, routes, dashboard, storeItems } = props;

  const storeTheme = useSelector((state) => state.theme.theme);

  const theme = useMemo(
    () =>
      createMasterTheme({
        primaryColor: primaryColor,
        type: storeTheme
      }),
    [storeTheme, primaryColor]
  );

  // Reduce the routes to keep only the elements needed
  const reducedRoutes = useMemo(
    () => routes && routes.map(({ name, icon, ...keepAttrs }) => keepAttrs),
    [routes]
  );

  // Get all the required firestore items into the redux store
  const standardItems = [
    {
      collection: "users",
      doc: `${props._user.uid}`,
      storeAs: "owner"
    }
  ];
  const newItems = standardItems.concat(storeItems(props));
  useFirestoreConnect(newItems);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        preventDuplicate
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Notifier />
        <Router history={history}>
          <Switch>
            {reducedRoutes &&
              reducedRoutes.map((route, i) => {
                const RouteType = route.type || Fragment;
                const Component = route.component;
                const Dashboard = dashboard;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact
                    render={(props) => (
                      <RouteType role={route.role}>
                        <Dashboard>
                          <Component {...props} />
                        </Dashboard>
                      </RouteType>
                    )}
                  />
                );
              })}
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ firebase: { auth }, firestore: { data } }) => ({
  _user: auth,
  _profile: data && data.owner,
  _isAuth: auth.isLoaded && !auth.isEmpty,
  _role: data.owner && data.owner.role
});

export default compose(connect(mapStateToProps))(App);
