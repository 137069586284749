import React, { useMemo, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { IconButton, Tooltip, Box, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Create";
import DownloadIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useTheme } from "@material-ui/core/styles";
import {
  SortingState,
  SelectionState,
  FilteringState,
  GroupingState,
  SearchState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  DataTypeProvider
  // IntegratedSummary,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  // TableSummaryRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  TableColumnReordering,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility
} from "@devexpress/dx-react-grid-material-ui";

import {
  Plugin,
  Template,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";

import { AddButton, StyledProgress } from "@austere-monorepo/components";

const getRowId = (row) => row.id;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper
  },
  table: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    height: "100%"
  },
  toolbarButtons: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1)
  },
  cell: {
    verticalAlign: "middle",
    padding: theme.spacing(0, 0.75),
    height: "45px"
  },
  avatar: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    margin: theme.spacing(1),
    verticalAlign: "middle",
    border: "1px solid",
    borderColor: theme.palette.divider
  },
  avatarIcon: {
    height: "60px",
    width: "60px",
    margin: theme.spacing(1),
    verticalAlign: "middle",
    color: theme.palette.warning
  },
  link: {
    color: theme.palette.secondary.main
  }
}));

function StyledTable({
  rows,
  columns,
  sorting,
  tableColumns,
  message,
  htmlColumns,
  emailColumns,
  whatsAppColumns,
  dateTimeColumns,
  dateColumns,
  ageColumns,
  imageColumns,
  hiddenColumns,
  groupedBy,
  expandedGroups,
  addButton,
  collection,
  doc
}) {
  const classes = useStyles();

  const theme = useTheme();

  const rowsData = rows;

  const tableColumnExtensionsDefault = [
    { columnName: "edit", width: 75, align: "center" },
    { columnName: "view", width: 75, align: "center" }
  ];

  const tableColumnExtensions = tableColumns
    ? tableColumns.concat(tableColumnExtensionsDefault)
    : tableColumnExtensionsDefault;

  function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  function export_table_to_csv(html, filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(","));
    }
    download_csv(csv.join("\n"), filename);
  }

  // delete code

  const ExportCsvPlugin = () => (
    <Plugin name="exportCsv">
      <Template name="toolbarContent">
        <Tooltip title="Export as CSV">
          <IconButton
            className={classes.toolbarButtons}
            size="small"
            onClick={function () {
              var html = document.querySelector("table").outerHTML;
              export_table_to_csv(
                html,
                collection + "_" + moment().format("YYYY_MM_DD") + ".csv"
              );
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const MessagePlugin = () => (
    <Plugin name="message">
      <Template name="toolbarContent">
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Typography align="center" variant="button" color="secondary" noWrap>
            {message}
          </Typography>
        </Box>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const [showFilter, changeFilter] = useState(false);
  const filterSwitch = () => changeFilter((showFilter) => !showFilter);
  const ExportFilterPlugin = () => (
    <Plugin name="showFilter">
      <Template name="toolbarContent">
        <Tooltip title="Show/hide filter">
          <IconButton
            className={classes.toolbarButtons}
            color={showFilter ? "secondary" : "default"}
            size="small"
            onClick={filterSwitch}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  const [showGrouping, changeGrouping] = useState(false);
  const groupingSwitch = () => changeGrouping((showGrouping) => !showGrouping);
  const ExportGroupingPlugin = () => (
    <Plugin name="showGrouping">
      <Template name="toolbarContent">
        <Tooltip title="Show/hide grouping">
          <IconButton
            className={classes.toolbarButtons}
            color={showGrouping ? "secondary" : "default"}
            size="small"
            onClick={groupingSwitch}
          >
            <SortIcon />
          </IconButton>
        </Tooltip>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  );

  // const TitlePlugin = ({ title }) => (
  //   <Plugin name='title'>
  //     <Template name='toolbarContent'>
  //       <Box display='flex' justifyContent='center' flexGrow='1'>
  //         <Typography variant='button'>{title}</Typography>
  //       </Box>
  //       <TemplatePlaceholder />
  //     </Template>
  //   </Plugin>
  // );

  const Cell = (props) => {
    if (props.column.name === "edit") {
      return (
        <VirtualTable.Cell className={classes.cell}>
          <IconButton
            // className={classes.icon}
            component={Link}
            to={`/${collection}/edit/${props.row.id}`}
            key={props.row.id}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </VirtualTable.Cell>
      );
    }
    if (props.column.name === "view") {
      return (
        <VirtualTable.Cell className={classes.cell}>
          <IconButton
            // className={classes.icon}
            component={Link}
            to={`/${collection}/view/${props.row.id}`}
            key={props.row.id}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        </VirtualTable.Cell>
      );
    }
    return <VirtualTable.Cell className={classes.cell} {...props} />;
  };

  const DateFormatter = ({ value }) => moment(value).format("DD-MMM-YY");
  const DateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const DateTimeFormatter = ({ value }) =>
    moment(value).format("DD-MMM-YY hh:mm");
  const DateTimeTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateTimeFormatter} {...props} />
  );

  const AgeFormatter = ({ value }) => `${moment().diff(value, "y")}yo`;
  const AgeTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={AgeFormatter} {...props} />
  );

  const EmailFormatter = ({ value }) => (
    <a className={classes.link} href={"mailto:" + value}>
      {value}
    </a>
  );
  const EmailTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={EmailFormatter} {...props} />
  );

  // Decorate WhatsApp columns with URL
  const WhatsappFormatter = ({ value }) => (
    <a className={classes.link} target="_blank" href={"https://wa.me/" + value}>
      {value}
    </a>
  );
  const WhatsappTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={WhatsappFormatter} {...props} />
  );

  const ImageFormatter = ({ value }) =>
    value ? (
      <img className={classes.avatar} src={value && value} />
    ) : (
      <AccountCircleIcon className={classes.avatarIcon} color="action" />
    );
  const ImageTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
  );

  const HtmlFormatter = ({ value }) => value.replace(/<(.|\n)*?>/g, "");
  const HtmlTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={HtmlFormatter} {...props} />
  );

  const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

  return (
    <Fragment>
      {rows && (
        <Box className={classes.root}>
          <Grid
            className={classes.table}
            rows={rowsData}
            columns={columns && columns}
            getRowId={getRowId}
            rootComponent={Root}
          >
            {showGrouping && <DragDropProvider />}

            {dateColumns && <DateTypeProvider for={dateColumns} />}

            {dateTimeColumns && <DateTimeTypeProvider for={dateTimeColumns} />}

            {ageColumns && <AgeTypeProvider for={ageColumns} />}

            {emailColumns && <EmailTypeProvider for={emailColumns} />}

            {whatsAppColumns && <WhatsappTypeProvider for={whatsAppColumns} />}

            {htmlColumns && <HtmlTypeProvider for={htmlColumns} />}

            {imageColumns && <ImageTypeProvider for={imageColumns} />}
            <FilteringState
              columnExtensions={[
                { columnName: "edit", filteringEnabled: false },
                { columnName: "view", filteringEnabled: false }
              ]}
            />
            <SearchState />
            <SortingState defaultSorting={sorting && sorting} />
            <GroupingState
              grouping={groupedBy && groupedBy}
              defaultExpandedGroups={expandedGroups}
            />
            <SelectionState />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedGrouping />
            {/* <IntegratedSummary /> */}

            <VirtualTable
              height="auto"
              columnExtensions={tableColumnExtensions}
              cellComponent={Cell}
            />
            {hiddenColumns && (
              <TableColumnVisibility defaultHiddenColumnNames={hiddenColumns} />
            )}
            <TableHeaderRow />
            <TableColumnReordering
              defaultOrder={columns.map((column) => column.name)}
            />
            {showFilter && <TableFilterRow showFilterSelector />}
            {/* <TableSelection showSelectAll /> */}
            <TableGroupRow />
            <Toolbar />

            <SearchPanel />
            {message && <MessagePlugin />}
            {/* <Hidden only="sm"> */}
            {theme.breakpoints.up("md") && showGrouping && (
              <GroupingPanel showSortingControls />
            )}
            {/* {props.summaryMessages && <TableSummaryRow messages={props.summaryMessages} />} */}
            {/* {theme.breakpoints.up("md") && props.title && <TitlePlugin title={props.title} />} */}
            <ExportCsvPlugin />
            <ExportFilterPlugin />
            <ExportGroupingPlugin />

            {hiddenColumns && <ColumnChooser />}
          </Grid>
        </Box>
      )}
      {!rows && <StyledProgress />}
      {addButton && <AddButton collection={collection} doc={doc} />}
    </Fragment>
  );
}

export default StyledTable;
