//

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import NoteForm from "./NoteForm";
import {
  StyledProgress,
  ViewButton,
  DeleteButton,
  SaveButton,
  handleFormDelete,
  handleFormEdit
} from "@austere-monorepo/components";

function EditNote(props) {
  const {
    handleFormEdit,
    handleFormDelete,
    handleSubmit,
    history,
    _note,
    _auth
  } = props;
  const deleteForm = () => {
    handleFormDelete({
      formCollection: `users/${_auth.uid}/notes`,
      formData: _note,
      auth: _auth
    });
    history.push("/notes");
  };

  const onSubmit = ({ id, ...formProps }) => {
    handleFormEdit({
      formCollection: `users/${_auth.uid}/notes`,
      formData: formProps,
      currentData: _note,
      auth: _auth
    });
    history.push("/notes");
  };
  return (
    <Fragment>
      {_note && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <NoteForm
            {...props}
            actionBar={
              <Fragment>
                <DeleteButton deleteForm={deleteForm} />
                <SaveButton />
                <ViewButton to={`/notes/view/${_note.id}`} />
              </Fragment>
            }
          />
        </form>
      )}
      {!_note && <StyledProgress />}
    </Fragment>
  );
}

function mapStateToProps(
  { firebase: { auth }, firestore: { ordered } },
  props
) {
  const currentNote =
    ordered.notes &&
    ordered.notes.filter((note) => note.id === props.match.params.id)[0];
  return {
    _auth: auth,
    _note: currentNote,
    initialValues: currentNote
  };
}

export default compose(
  connect(mapStateToProps, {
    handleFormDelete,
    handleFormEdit
  }),
  reduxForm({
    form: `noteForm`
  })
)(EditNote);
