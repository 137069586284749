import React from "react";
import { FormWrapper, FormGeo, FormImage } from "@austere-monorepo/components";
import NeedToKnow from "./Form_NeedToKnow";
import Observations from "./Form_Observations";
import TotalBodyExam from "./Form_TotalBodyExam";
import Enquire from "./Form_Enquire";
import Summary from "./Form_Summary";
import Map from "client/map/Map";

function NoteForm(props) {
  return (
    <FormWrapper
      title="N.O.T.E.S"
      headings={[
        "Need To Know",
        "Observations",
        "Total Body Exam",
        "Enquire",
        "Summary",
        "Location",
        "Images"
      ]}
      {...props}
    >
      <NeedToKnow />
      <Observations />
      <TotalBodyExam />
      <Enquire />
      <Summary />
      <FormGeo {...props} displayMap={<Map form />} />
      <FormImage {...props} maxImages={2} />
    </FormWrapper>
  );
}

export default NoteForm;
