import React, { PureComponent } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { reset, reduxForm } from "redux-form";
import {
  FormWrapper,
  ActionBar,
  RenderTextField,
  RenderHiddenField,
  RenderFormSection,
  history,
  handleFormAdd,
  validate
} from "@austere-monorepo/components";
import { Field } from "redux-form";

class AddUser extends PureComponent {
  onSubmit = (formProps) => {
    this.props.handleFormAdd({
      formCollection: "users",
      formData: formProps,
      auth: this.props._auth
    });
    this.props.dispatch(reset("addUserForm"));
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <ActionBar title="Add User" save />
        <FormWrapper>
          <RenderFormSection heading="User Details" />
          <Field
            name="displayName"
            label="First name & last name"
            type="text"
            placeholder="First name & last name."
            component={RenderTextField}
            md={6}
          />
          <Field
            name="email"
            label="Email"
            type="email"
            placeholder="Email Address"
            component={RenderTextField}
            md={6}
          />
          <Field name="role" type="number" component={RenderHiddenField} />
        </FormWrapper>
      </form>
    );
  }
}

const mapStateToProps = ({ firebase: { auth } }) => ({
  _auth: auth,
  initialValues: {
    role: 1
  }
});

export default compose(
  connect(mapStateToProps, { handleFormAdd }),
  reduxForm({
    form: "addUserForm"
    //

    // validate,
  })
  // firestoreConnect([{ collection: "users" }])
)(AddUser);
