import React, { Fragment, useState } from "react";
import { icon } from "leaflet";
import { Link } from "react-router-dom";
import { Marker, Tooltip, useMap } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DirectionsIcon from "@material-ui/icons/Directions";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: "calc(100vw + 240px)",
    height: "76%",
    [theme.breakpoints.down("sm")]: {
      width: "100vw"
    }
  },
  dialogPaper: {
    boxShadow: theme.shadows[1],
    maxWidth: "300px",
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5)
  }
}));

function StyledMarker(props) {
  const { status, title, position, description, type, collection, id, marker } =
    props;

  const classes = useStyles();

  const map = useMap();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const myIcon = icon({
    iconUrl: marker,
    iconSize: [30, 30], // pixels
    iconAnchor: [13, 32]
  });

  return (
    <Fragment>
      <Marker
        eventHandlers={{
          click: (e) => {
            map.setView(position, 16);
            setOpen(true);
          }
        }}
        position={position}
        icon={myIcon}
        title={title}
        type={type}
      >
        {!open && (
          <Tooltip opacity={1} permanent={status === "Complete" ? false : true}>
            {title}
            {": "}
            {description}
          </Tooltip>
        )}
      </Marker>
      <Dialog
        className={classes.dialog}
        classes={{
          container: classes.dialogContainer,
          paper: classes.dialogPaper,
          backDrop: classes.dialogBackdrop
        }}
        hideBackdrop
        open={open}
        onClose={handleClose}
      >
        <DialogTitle disableTypography>
          {title}
          <IconButton
            size="small"
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{description}</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="small"
            startIcon={<DirectionsIcon />}
            component={"a"}
            href={`https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`}
            target="_blank"
          >
            Google
          </Button>
          <Button
            size="small"
            startIcon={<SearchIcon />}
            component={Link}
            to={`/${collection}/view/${id}`}
          >
            View
          </Button>
          <Button
            size="small"
            startIcon={<EditIcon />}
            component={Link}
            to={`/${collection}/edit/${id}`}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default StyledMarker;
