import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMap, Tooltip, Circle, CircleMarker } from "react-leaflet";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import MapButton from "./MapButton";
import { withSnackbar } from "notistack";

function Locate(props) {
  const map = useMap();

  const dispatch = useDispatch();

  const [locate, setLocate] = useState(false);

  function toggleLocate() {
    setLocate(!locate);
  }

  const [position, setPosition] = useState(null);

  const [accuracy, setAccuracy] = useState(null);

  const ringStyle = {
    weight: 1,
    color: "blue",
    fillColor: "blue",
    fillOpacity: 0.2
  };

  const markerStyle = {
    radius: 10,
    weight: 3,
    color: "white",
    fillColor: "blue",
    fillOpacity: 1
  };

  useEffect(() => {
    if (locate) {
      map
        .locate({
          watch: true,
          enableHighAccuracy: true,
          setView: true
        })
        .on("locationfound", function (e) {
          setPosition(e.latlng);
          setAccuracy(e.accuracy);
          dispatch({
            type: "UPDATE_MAP_ACCURACY",
            payload: {
              accuracy: e.accuracy
            }
          });
        })
        .on("locationerror", function (error) {
          props.enqueueSnackbar(error.message, {
            variant: "error"
          });
          setLocate(false);
        });
    }
    if (!locate) {
      map.stopLocate();
      setPosition(null);
      setAccuracy(null);
      dispatch({
        type: "UPDATE_MAP_ACCURACY",
        payload: {
          accuracy: null
        }
      });
    }
  }, [locate]);

  return (
    <Fragment>
      <MapButton
        title={locate ? "Click to disable location" : "Click to locate"}
        onClick={toggleLocate}
        order={3}
        disabled={props.disabled}
      >
        <LocationSearchingIcon
          fontSize="small"
          style={{ color: locate ? "orange" : "" }}
        />
      </MapButton>
      {position && accuracy && (
        <CircleMarker
          center={position}
          pathOptions={ringStyle}
          radius={accuracy === null ? 100 : accuracy * 2}
        >
          <Circle center={position} pathOptions={markerStyle} radius={6}>
            <Tooltip direction="bottom" offset={[0, 10]} opacity={1} permanent>
              Accuracy{" "}
              {accuracy === null ? "Searching..." : `${accuracy.toFixed(0)} m`}
            </Tooltip>
          </Circle>
        </CircleMarker>
      )}
    </Fragment>
  );
}

export default withSnackbar(Locate);
