import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useMap, useMapEvents } from "react-leaflet";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import MapButton from "./MapButton";

function Zoom() {
  const map = useMap();

  const dispatch = useDispatch();

  function zoomIn() {
    map.setZoom(map.getZoom() + 1);
  }
  function zoomOut() {
    map.setZoom(map.getZoom() - 1);
  }

  useMapEvents({
    zoomend: () => {
      dispatch({
        type: "UPDATE_MAP_ZOOM",
        payload: {
          zoom: map.getZoom(),
        },
      });
    },
  });

  return (
    <Fragment>
      <MapButton fontSize='small' title={"Zoom in"} order={1} onClick={zoomIn}>
        <ZoomInIcon />
      </MapButton>
      <MapButton fontSize='small' title={"Zoom out"} order={2} onClick={zoomOut}>
        <ZoomOutIcon />
      </MapButton>
    </Fragment>
  );
}

export default Zoom;
