import React, { Fragment, useState } from "react";
import { FieldArray } from "redux-form";
import {
  RenderTextField,
  RenderSelectField,
  RenderFormSection
} from "@austere-monorepo/components";
import { Field } from "redux-form";
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddIcon from "@material-ui/icons/Add";

function RenderObservations({ fields }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (index) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (index) => {
    setOpen(false);
    fields.remove(index);
    // deleteForm();
  };
  return (
    <Fragment>
      {fields.map((obs, index) => (
        <Fragment key={index}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to delete Observations #${index + 1}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                style={{ color: "red", borderColor: "red" }}
                onClick={() => handleDelete(index)}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <RenderFormSection heading={`Observations #${index + 1}`}>
            <Field
              name={`${obs}.time`}
              type="time"
              label="Time"
              // format={(value) => moment(value).format("HH:mm")}
              // parse={(value) => new Date(value).getTime()}
              // defaultValue={new Date().getTime()}
              placeholder="Time (tap to select)"
              component={RenderTextField}
              // actions={
              //   <Button
              //     style={{ fontSize: 9, color: "red", padding: 0 }}
              //     onClick={() => fields.remove(index)}
              //   >
              //     Delete
              //   </Button>
              // }
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.loc`}
              component={RenderSelectField}
              label={"Level of Consciousness"}
              items={[
                { label: "alert", val: "alert" },
                { label: "verbal", val: "verbal" },
                { label: "pain", val: "pain" },
                { label: "unresponsive", val: "unresponsive" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.hr`}
              placeholder="0 - 300"
              label={"Heart Rate"}
              type="number"
              component={RenderTextField}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.hr_rhythm`}
              component={RenderSelectField}
              label={"Heart Rhythm"}
              items={[
                { label: "regular", val: "regular" },
                { label: "irregular", val: "irregular" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.hr_quality`}
              component={RenderSelectField}
              label={"Heart Quality"}
              items={[
                { label: "strong", val: "strong" },
                { label: "weak", val: "weak" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.rr`}
              placeholder="0 - 60"
              label={"Breathing Rate"}
              type="number"
              component={RenderTextField}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.rr_rhythm`}
              component={RenderSelectField}
              label={"Breathing Rhythm"}
              items={[
                { label: "regular", val: "regular" },
                { label: "irregular", val: "irregular" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.rr_quality`}
              component={RenderSelectField}
              label={"Breathing Quality"}
              items={[
                { label: "normal", val: "normal" },
                { label: "wheeze", val: "wheeze" },
                { label: "stridor", val: "stridor" },
                { label: "gurgling", val: "gurgling" },
                { label: "snoring", val: "snoring" },
                { label: "coughing", val: "coughing" },
                { label: "whooping", val: "whooping" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.skin_color`}
              component={RenderSelectField}
              label={"Skin Color"}
              items={[
                { label: "pink", val: "pink" },
                { label: "red", val: "red" },
                { label: "pale", val: "pale" },
                { label: "blue", val: "blue" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.skin_temp`}
              component={RenderSelectField}
              label={"Skin Temperature"}
              items={[
                { label: "hot", val: "hot" },
                { label: "warm", val: "warm" },
                { label: "cool", val: "cool" },
                { label: "cold", val: "cold" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.skin_moisture`}
              component={RenderSelectField}
              label={"Skin Moisture"}
              items={[
                { label: "dry", val: "dry" },
                { label: "clammy", val: "clammy" },
                { label: "wet", val: "wet" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.pupils_symmetry`}
              component={RenderSelectField}
              label={"Pupils Symmetry"}
              items={[
                { label: "equal", val: "equal" },
                { label: "L > R", val: "L > R" },
                { label: "R < L", val: "R < L" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.pupils_reactivity`}
              component={RenderSelectField}
              label={"Pupils Reactivity"}
              items={[
                { label: "reactive", val: "reactive" },
                { label: "fixed", val: "fixed" }
              ]}
              md={3}
              xs={6}
            />
            <Field
              name={`${obs}.pupils_diameter`}
              component={RenderSelectField}
              label={"Pupils Diameter"}
              items={[
                { label: "normal", val: "normal" },
                { label: "dilated", val: "dilated" },
                { label: "constricted", val: "constricted" },
                { label: "asymmetrical", val: "asymmetrical" }
              ]}
              md={3}
              xs={6}
            />
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                style={{ color: "red", borderColor: "red" }}
                // onClick={() => fields.remove(index)}
                onClick={() => handleClickOpen(index)}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
          </RenderFormSection>
        </Fragment>
      ))}

      <Grid item xs={12}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          style={{ paddingBottom: "8px" }}
        >
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={() => fields.push({})}
          >
            <AddIcon />
          </Button>
        </Box>
      </Grid>
    </Fragment>
  );
}

function Form_Observations() {
  return (
    <RenderFormSection divider heading={"Observations"}>
      <FieldArray name="obs" component={RenderObservations} />
    </RenderFormSection>
  );
}

export default Form_Observations;
