import React, { Fragment } from 'react';
import UpdateIcon from '@material-ui/icons/SyncSharp';
import { Tooltip, IconButton } from '@material-ui/core';
// import { StyledProgress } from '@austere-monorepo/components';

function RefreshButton() {
  // const [refreshing, setRefreshing] = useState(false);

  const handleClick = (e) => {
    // setRefreshing(true);
    window.history.go(0);
    // setRefreshing(false);
  };
  return (
    <Fragment>
      <Tooltip title="Refresh page">
        <IconButton color="inherit" onClick={handleClick}>
          <UpdateIcon />
        </IconButton>
      </Tooltip>
      {/* {refreshing && <StyledProgress message={'Reloading App'} />} */}
    </Fragment>
  );
}

export default RefreshButton;
