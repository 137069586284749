import PropTypes from "prop-types";
import { Grid, Card, CardHeader } from "@material-ui/core";
import { getHeading } from "@austere-monorepo/components";

function RenderFormSection({ heading, subHeading, children }) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          id={heading ? getHeading(heading) : null}
          title={heading}
          subheader={subHeading}
        />
        <Grid container spacing={3} alignItems="stretch">
          {children}
        </Grid>
      </Card>
    </Grid>
  );
}

RenderFormSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  id: PropTypes.string,
  divider: PropTypes.string
};

export default RenderFormSection;
