import React, { Fragment, useState, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Document,
  StyledSideView,
  Search,
  getHash
} from "@austere-monorepo/components";
import { NavLink, Redirect, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(0),
    margin: theme.spacing(0.5, 1, 0, 0.5),
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: "0.7rem",
    lineHeight: 1.5
  },
  doc: {
    padding: theme.spacing(0),
    fontSize: "0.4rem",
    "& .MuiListItemText-root": {
      margin: theme.spacing(0.5, 0, 0.5, 2)
    }
  },
  heading: {
    padding: theme.spacing(0),
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    "& .MuiListItemText-root > span": {
      fontSize: "0.8rem",
      padding: theme.spacing(0),
      margin: theme.spacing(0.2, 0, 0.2, 3)
    }
  },
  listText: {
    margin: theme.spacing(0.15, 0)
  }
}));

function HandbookLayout(props) {
  // console.log(props);
  const { docs, title } = props;
  const classes = useStyles();
  // const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const validId = props.match.params.path;
  // console.log(validId);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const fetchedPost = {};

  let postExists = false;

  docs.forEach(function (obj) {
    const res = obj.children.map(function (post) {
      if (validId === post.path) {
        fetchedPost.title = post.name ? post.name : "No title given";
        fetchedPost.updated = post.updated;
        fetchedPost.author = post.author;
        fetchedPost.content = post.content ? post.content : "No content given";
        fetchedPost.headings = post.headings ? post.headings : null;
        fetchedPost.path = post.path;
        postExists = true;
      }
      return res;
    });
    if (postExists === false) {
      return <Redirect to="/404" />;
    }
  });

  const location = useLocation();

  const drawerList = useMemo(
    () => (
      <Box onClick={mobileOpen ? handleDrawerToggle : null}>
        {/* Home */}
        <List dense>
          <ListItem
            button
            className={classes.doc}
            selected={fetchedPost.path === "home"}
            component={NavLink}
            to={"home"}
          >
            <ListItemText primary={"Home"} />
          </ListItem>
        </List>
        {/* Section */}
        {docs.map(
          (section) =>
            section.name && (
              <List
                dense
                subheader={
                  <ListSubheader
                    disableGutters
                    disableSticky
                    className={classes.section}
                  >
                    {section.name}
                  </ListSubheader>
                }
              >
                {section.children &&
                  section.children.map((doc, i) => (
                    <Fragment>
                      <ListItem
                        key={i}
                        button
                        className={classes.doc}
                        selected={fetchedPost.path === doc.path}
                        component={NavLink}
                        to={doc.path}
                      >
                        <ListItemText primary={doc.name} />
                      </ListItem>
                      {fetchedPost.path === doc.path && (
                        <Fragment>
                          {doc.headings && (
                            <Fragment>
                              {doc.headings.map((heading, i) => (
                                <ListItem
                                  key={i}
                                  button
                                  component={HashLink}
                                  to={getHash(heading)}
                                  selected={location.hash === getHash(heading)}
                                  className={classes.heading}
                                >
                                  <ListItemText
                                    className={classes.listText}
                                    primary={heading}
                                  />
                                </ListItem>
                              ))}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
              </List>
            )
        )}
      </Box>
    ),
    [fetchedPost]
  );

  return (
    <StyledSideView
      title={title}
      sidebar={drawerList}
      actionBar={<Search docs={docs} />}
      content={
        postExists && (
          <Document
            html={fetchedPost.content}
            title={fetchedPost.title}
            updated={fetchedPost.updated}
            author={fetchedPost.author}
          />
        )
      }
    />
  );
}

export default HandbookLayout;
