import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import NoteForm from "./NoteForm";
import { handleFormAdd, SaveButton } from "@austere-monorepo/components";

function AddNote(props) {
  const { handleFormAdd, handleSubmit, history, _auth } = props;
  const onSubmit = (formProps) => {
    handleFormAdd({
      formCollection: `users/${_auth.uid}/notes`,
      formData: formProps,
      auth: _auth
    });
    history.push("/notes");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoteForm
        {...props}
        actionBar={
          <Fragment>
            <SaveButton />
          </Fragment>
        }
      />
    </form>
  );
}

const mapStateToProps = ({ firebase: { auth } }) => ({
  _auth: auth,
  initialValues: {
    obs: [
      {
        time: "",
        loc: "",
        hr: "",
        hr_rhythm: "",
        hr_quality: "",
        rr: "",
        rr_rhythm: "",
        rr_quality: "",
        skin_color: "",
        skin_temp: "",
        skin_moisture: "",
        pupils_symmetry: "",
        pupils_reactivity: "",
        pupils_diameter: ""
      }
    ]
  }
});

export default compose(
  connect(mapStateToProps, { handleFormAdd }),
  reduxForm({
    form: "noteForm"
  })
)(AddNote);
