// https://github.com/m87wheeler/react-search-bar/blob/master/src/components/Item.js

import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Typography,
  OutlinedInput,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ReactMarkdown from "markdown-to-jsx";
import { SearchButton } from "@austere-monorepo/components";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
}));

const Item = (props) => {
  const classes = useStyles();

  const createMarkup = (html) => {
    return { __html: html };
  };

  const options = {
    overrides: {
      p: {
        component: Typography,
        props: { variant: "caption", component: "span", color: "textPrimary" }
      },
      span: {
        component: Typography,
        props: { variant: "caption", component: "span" }
      },
      li: {
        component: Typography,
        props: { variant: "caption", component: "span" }
      }
    }
  };

  return (
    <ListItem
      dense
      button
      component={Link}
      to={props.path}
      onClick={props.onClick}
    >
      <ListItemText
        primary={
          <Typography dangerouslySetInnerHTML={createMarkup(props.name)} />
        }
        secondary={
          <ReactMarkdown className={classes.link} options={options}>
            {props.content}
          </ReactMarkdown>
        }
      />
    </ListItem>
  );
};

const Search = (props) => {
  const classes = useStyles();
  const { docs } = props;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);

  const newDocs = docs.map((doc) => doc.children).flat(1);

  useEffect(() => {
    setData(newDocs);
    setIsLoading(false);
  }, []);

  // Search And Highlight Function
  const handleInput = (e) => {
    let str = e.target.value;
    setSearch(str);
    console.log(search);
    const newArr = data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(str.toLowerCase()) ||
          item.content.toLowerCase().includes(str.toLowerCase())
      )
      .map((item) => {
        let newTitle = item.name.replace(
          new RegExp(str, "gi"),
          (match) =>
            `<mark style="background: #2769AA; color: white;">${match}</mark>`
        );
        let newBody = item.content
          .replace(/[^\w\s]/gi, "")
          .slice(item.content.indexOf(str) - 75, item.content.indexOf(str) + 75)
          .replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );
        return {
          ...item,
          name: newTitle,
          content: newBody
        };
      });
    setSearchData(newArr);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchData([]);
  };

  return (
    <Fragment>
      <SearchButton onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle disableTypography>
          <OutlinedInput
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            fullWidth
            notched
            autoFocus
            onInput={(e) => handleInput(e)}
            startAdornment={<SearchIcon />}
          />
          {search.length > 0 && (
            <Typography variant="caption">
              {searchData.length === 1
                ? `${searchData.length} result`
                : `${searchData.length} results`}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {isLoading && <Typography variant="caption">Loading...</Typography>}
          {search.length > 0 && (
            <List disablePadding dense>
              {searchData.map((post) => (
                <Item
                  key={post.id}
                  user={post.userId}
                  name={post.name}
                  content={post.content}
                  path={post.path}
                  onClick={handleClose}
                />
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Search;
