// https://www.codota.com/code/javascript/classes/react-leaflet/GeoJSON

import React, { useState, useEffect } from "react";
import { MapContainer } from "react-leaflet";
import { connect } from "react-redux";
import Locate from "./Locate";
// import Legend from "./Legend";
import Center from "./Center";
import Zoom from "./Zoom";
import Coordinates from "./Coordinates";
import AddPoint from "./Point";
import ViewPoints from "./ViewPoints";
// import Ruler from './Ruler';
import Circle from "./Circle";
import Search from "./Search";
import Layers from "./Layers";
// import ChangeCenter from './ChangeCenter';
import "./styles.css";
import { StyledProgress } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > #main-map": {
      width: "100%",
      position: "absolute",
      top: 56,
      bottom: 0,
      [theme.breakpoints.up("md")]: {
        width: "calc(100vw - 200px)"
      }
    },
    "& > #form-map": {
      height: "310px",
      position: "relative",
      border: `1px solid ${theme.palette.divider}`
    }
  }
}));

function MapComponent({
  _lat,
  _lng,
  _zoom,
  // _base,
  form,
  items,
  maxBounds,
  onMove,
  dragging,
  zoomButtons,
  onDrag,
  locate,
  children,
  coordinates,
  // legend,
  addPoints,
  viewPoints,
  search,
  // center,
  circle,
  baseLayers,
  offlineLayers,
  imageBaseLayers,
  dataLayers,
  geoJsonLayers,
  // initBaseLayer,
  // initDataLayers,
  _baseLayer,
  _dataLayers
}) {
  const classes = useStyles();

  const [state, setState] = useState({ center: [null, null], zoom: null });

  // ***working - don't delete***
  useEffect(() => {
    if (window.location.hash !== "") {
      var hash = window.location.hash.replace("#", "");
      var parts = hash.split(",");
      if (parts.length === 3) {
        console.log(parts);
        setState({
          center: [parseFloat(parts[0]), parseFloat(parts[1])],
          // zoom: parseInt(parts[2].slice(0, -1), 10),
          zoom: Number(parts[2])
        });
        console.log("stateifhash", state.zoom);
      }
    } else if (window.location.hash === "") {
      setState({
        center: [_lat, _lng],
        zoom: _zoom
      });
    } else {
      setState({
        center: [51.505, -0.09],
        zoom: 12
      });
    }
  }, []);

  // console.log(form);

  return (
    <div className={classes.root}>
      {state.center && state.zoom && (
        <MapContainer
          id={form ? "form-map" : "main-map"}
          preferCanvas={true}
          // minZoom={1}
          // maxZoom={18}
          zoomControl={false}
          attributionControl={false}
          center={state.center}
          zoom={state.zoom}
          maxBounds={
            maxBounds
              ? maxBounds
              : [
                  [-90, -180],
                  [90, 180]
                ]
          }
          onMove={onMove}
          placeholder={<StyledProgress />}
          onDrag={onDrag}
          dragging={dragging}
        >
          <Layers
            baseLayers={baseLayers}
            offlineLayers={offlineLayers}
            imageBaseLayers={imageBaseLayers}
            dataLayers={dataLayers}
            geoJsonLayers={geoJsonLayers}
            items={items}
            initBaseLayer={_baseLayer}
            initDataLayers={_dataLayers}
          />
          {zoomButtons && <Zoom />}
          {/* <ChangeView /> */}
          <Center />
          {/* <ChangeCenter /> */}
          {circle && <Circle />}
          {/* {hash && <Hash />} */}
          {search && <Search />}
          {locate && <Locate />}
          {coordinates && <Coordinates visible={form ? false : true} />}
          {/* {legend && <Legend items={items} />} */}
          {/* {ruler && <Ruler />} */}
          {addPoints && <AddPoint items={items} />}
          {viewPoints && <ViewPoints />}
          {children}
        </MapContainer>
      )}
      {!state.center || (!state.zoom && <StyledProgress />)}
    </div>
  );
}
function mapStateToProps(state) {
  // console.log(state.mapState);
  return {
    _lat: state.mapState.lat,
    _lng: state.mapState.lng,
    _zoom: state.mapState.zoom,
    _baseLayer: state.mapState.base,
    _dataLayers: state.mapState.data
    // _base: state.mapState.base
  };
}

export default connect(mapStateToProps)(MapComponent);
