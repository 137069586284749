import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { MapContainer, CircleMarker, TileLayer } from "react-leaflet";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  map: {
    border: `1px solid ${theme.palette.divider}`,
    height: "300px",
    "& .leaflet-container": {
      height: "300px",
      width: "100%"
    }
  },
  link: {
    padding: theme.spacing(1),
    width: "100%",
    textAlign: "center"
  }
}));

function MiniMap({ lat, lng, zoom, id, circle }) {
  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  const baseURL = "https://api.mapbox.com/styles/v1/austere-rm/";
  const endURL = "/tiles/256/{z}/{x}/{y}?access_token=";
  const classes = useStyles();
  const position = [lat, lng];
  return (
    <Fragment>
      {Number(lat) && Number(lng) ? (
        <Fragment>
          <Box>
            <MapContainer
              className={classes.map}
              id={id}
              attributionControl={false}
              center={position}
              zoom={zoom ? zoom : 9}
              scrollWheelZoom={false}
            >
              <TileLayer
                url={baseURL + "ck8jkyb5q0a0j1imlxiwyhgdb" + endURL + token}
              />
              {circle && <CircleMarker center={position} radius={20} />}
            </MapContainer>
          </Box>
          <Box className={classes.link}>
            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: 500 }}
            >
              Coordinates: {lat},{lng}{" "}
              <Typography
                variant="body2"
                component={Link}
                to={`/map#${lat},${lng},9`}
              >
                (View on map)
              </Typography>
            </Typography>
          </Box>
        </Fragment>
      ) : (
        `Map not available`
      )}
    </Fragment>
  );
}

MiniMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  id: PropTypes.any.isRequired,
  circle: PropTypes.bool
};

export default MiniMap;
