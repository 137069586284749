import ReactDOM from "react-dom";
import {
  App,
  store,
  rrfProps,
  serviceWorkerRegistration
} from "@austere-monorepo/components";
import Dashboard from "client/dashboard/Dashboard";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";
import { primaryColor, storeItems } from "config";
import { items } from "./routes";

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App
        primaryColor={primaryColor}
        dashboard={Dashboard}
        routes={items}
        storeItems={storeItems}
      />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: "SW_INIT" }),
  onUpdate: (registration) =>
    store.dispatch({ type: "SW_UPDATE", payload: registration })
});
