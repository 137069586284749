import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import { Field } from "redux-form";
import { Grid, Button, Box } from "@material-ui/core";
import {
  RenderTextField,
  RenderFormSection
} from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  mapBlock: {
    zIndex: 9999,
    pointerEvents: "none"
  }
}));

function FormGeo({
  displayMap,
  _lat,
  _lng,
  // _zoom,
  _accuracy,
  open,
  noHeader,
  subHeading,
  ...props
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const formName = props.form;

  const [map, setMap] = useState(open ? true : false);

  const [draggable, setDraggable] = useState(false);

  // Lock the map is the user scrolls
  // const [y, setY] = useState(window.scrollY);

  // const handleScroll = useCallback(
  //   (e) => {
  //     if (y > window.scrollY) {
  //       console.log("scrolling up");
  //     } else if (y < window.scrollY) {
  //       console.log("scrolling down");
  //     }
  //     setY(window.scrollY);
  //   },
  //   [y]
  // );

  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  const currentLat = useSelector(
    (state) =>
      state.form[formName].values && state.form[formName].values.latitude
  );
  const currentLng = useSelector(
    (state) =>
      state.form[formName].values && state.form[formName].values.longitude
  );

  useEffect(() => {
    if (currentLat && currentLng) {
      setMap(true);
      dispatch({
        type: "UPDATE_MAP_CENTER",
        payload: {
          lat: currentLat,
          lng: currentLng
        }
      });
      dispatch({
        type: "UPDATE_MAP_ZOOM",
        payload: {
          zoom: 14
        }
      });
    } else null;
  }, [currentLat, currentLng]);

  const handleChange = () => {
    setMap((map) => !map);
    setDraggable(true);
  };

  const handleChangeDraggable = () => {
    setDraggable((draggable) => !draggable);
  };

  const clearValues = () => {
    dispatch(change(formName, "latitude", null));
    dispatch(change(formName, "longitude", null));
    dispatch(change(formName, "accuracy", null));
    setMap(false);
  };

  const changeLat = (event, newValue) => {
    dispatch({
      type: "UPDATE_MAP_CENTER",
      payload: {
        lat: newValue,
        lng: _lng
      }
    });
  };

  const changeLng = (event, newValue) => {
    dispatch({
      type: "UPDATE_MAP_CENTER",
      payload: {
        lat: _lat,
        lng: newValue
      }
    });
  };

  useEffect(() => {
    if (map) {
      dispatch(change(formName, "latitude", _lat));
      dispatch(change(formName, "longitude", _lng));
      dispatch(change(formName, "accuracy", _accuracy));
    } else {
    }
  }, [_lat, _lng, _accuracy, map]);

  return (
    <RenderFormSection heading="Location" subHeading={subHeading}>
      <Grid item xs={12}>
        {!currentLat && !currentLng && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleChange}
          >
            Add Coordinates
          </Button>
        )}
        {map && (
          <Button
            //  className={classes.deleteButton}
            size="small"
            onClick={handleChangeDraggable}
            startIcon={
              draggable ? <PanToolOutlinedIcon /> : <LockOutlinedIcon />
            }
          >
            {draggable ? "Map Unlocked" : "Unlock Map"}
          </Button>
        )}

        {map && (
          <Button
            className={classes.deleteButton}
            size="small"
            onClick={clearValues}
            startIcon={<DeleteIcon />}
          >
            Clear
          </Button>
        )}
      </Grid>

      {map && (
        <Fragment>
          {draggable ? (
            <Grid item xs={12} md={9}>
              {displayMap}
            </Grid>
          ) : (
            <Grid item xs={12} md={9}>
              <Box className={classes.mapBlock}>{displayMap}</Box>
            </Grid>
          )}
        </Fragment>
      )}
      {currentLat && currentLng && (
        <Grid item md={map ? 3 : 12} xs={12}>
          <Grid
            container
            spacing={map ? 2 : 3}
            direction={map ? "column" : "row"}
          >
            <Field
              name="latitude"
              label="Latitude"
              type="number"
              step="any"
              onChange={changeLat}
              parse={(value) => Number(value)}
              component={RenderTextField}
              // value={lat}
              helper={map ? "At map center" : "Enter latitude"}
              // disabled={map === false}
              md={map ? 12 : 4}
            />
            <Field
              name="longitude"
              label="Longitude"
              type="number"
              step="any"
              parse={(value) => Number(value)}
              component={RenderTextField}
              onChange={changeLng}
              // disabled={map === false}
              helper={map ? "At map center" : "Enter longitude"}
              md={map ? 12 : 4}
            />
            <Field
              name="accuracy"
              label="Accuracy"
              type="number"
              step="any"
              parse={(value) => Number(value).toFixed(0)}
              component={RenderTextField}
              placeholder="N/A"
              helper={"Accuracy (m) - not map"}
              disabled
              md={map ? 12 : 4}
            />
          </Grid>
        </Grid>
      )}
    </RenderFormSection>
  );
}

function mapStateToProps(state) {
  return {
    _lat: state.mapState.lat,
    _lng: state.mapState.lng,
    _zoom: state.mapState.zoom,
    _accuracy: state.mapState.accuracy
  };
}

FormGeo.propTypes = {
  displayMap: PropTypes.node.isRequired
};

export default connect(mapStateToProps)(FormGeo);
