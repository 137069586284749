import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import { getFirebase } from "react-redux-firebase";
import { Grid, Button, Typography } from "@material-ui/core";
import {
  history,
  FormWrapper,
  RenderTextField,
  RenderFormSection
} from "@austere-monorepo/components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function handleLogout() {
  const firebase = getFirebase();
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: "Logging out...",
          options: {
            variant: "warning"
          }
        }
      });
      await firebase.auth().signOut();
      firebase.logout().then(() => {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            key: new Date().getTime() + Math.random(),
            message: "Logged out successfully",
            options: {
              variant: "success"
            }
          }
        });
        history.go();
      });
    } catch (error) {
      dispatch({
        type: "ENQUEUE_SNACKBAR",
        notification: {
          key: new Date().getTime() + Math.random(),
          message: `Logout failed ${error.message}`,
          options: {
            variant: "error"
          }
        }
      });
    }
  };
}

// const handleLogout = () => {
//   const firebase = getFirebase();
//   return firebase
//     .auth()
//     .signOut()
//     .then(() => {
//       history.go();
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

function MyAccount(props) {
  const { handleLogout } = props;
  return (
    <FormWrapper>
      <RenderFormSection heading="Details">
        <Field
          name="displayName"
          label="Name"
          type="text"
          component={RenderTextField}
          disabled
          md={6}
        />
        <Field
          name="email"
          label="Email"
          type="text"
          component={RenderTextField}
          disabled
          md={6}
        />
        <Grid item xs={12}>
          <Typography
            display="block"
            component={Link}
            to="/change-name"
            gutterBottom
          >
            Change Name
          </Typography>
          <Typography
            display="block"
            component={Link}
            to="/change-email"
            gutterBottom
          >
            Change Email
          </Typography>
          <Typography
            display="block"
            component={Link}
            to="/reset-password"
            gutterBottom
          >
            Reset Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleLogout}
            startIcon={<ExitToAppIcon />}
          >
            Logout
          </Button>
        </Grid>
      </RenderFormSection>
    </FormWrapper>
  );
}

const mapStateToProps = ({ firestore: { ordered } }) => ({
  initialValues: ordered.owner && {
    ...ordered.owner[0]
  }
});

export default compose(
  connect(mapStateToProps, { handleLogout }),
  reduxForm({
    form: "accountForm",
    enableReinitialize: true
  })
)(MyAccount);
