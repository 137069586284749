import React from "react";
import PropTypes from "prop-types";
import { Markdown, StyledWrapper } from "@austere-monorepo/components";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Breadcrumbs, Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 3, 0)
  }
}));

function Document({
  title,
  collection,
  message,
  author,
  updated,
  doc,
  html,
  children
}) {
  const classes = useStyles();
  return (
    <StyledWrapper>
      <Card>
        {title && <Typography variant="h1">{title}</Typography>}
        <Breadcrumbs separator="|" aria-label="breadcrumb">
          {collection && (
            <Typography variant="caption" color="textSecondary">
              {collection}
            </Typography>
          )}
          {message && (
            <Typography variant="caption" color="infoMain">
              {message}
            </Typography>
          )}
          {author && (
            <Typography variant="caption" color="textSecondary">
              Author: {author}
            </Typography>
          )}
          {updated && (
            <Typography variant="caption" color="textSecondary">
              {/* Updated: {moment(updated).format('DD-MMM-YYYY')} */}
              Updated {updated}
            </Typography>
          )}
        </Breadcrumbs>
        <Divider className={classes.divider} />
        {doc && <Markdown doc={doc} />}
        {html && <Markdown html={html} />}
        {children}
        {/* {images && (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Images
              </Typography>
            </Grid>
            {images.map((image, i) => (
              <Grid key={i} item md={6} xs={12} className={classes.image}>
                <img src={image.image} style={{ maxWidth: '100%' }} />
                <Typography align="center">{image.caption}</Typography>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      )} */}
      </Card>
    </StyledWrapper>
  );
}

Document.propTypes = {
  doc: PropTypes.object,
  title: PropTypes.string.isRequired,
  collection: PropTypes.string,
  message: PropTypes.string,
  author: PropTypes.string,
  updated: PropTypes.string
};

export default Document;
