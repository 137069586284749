const initState = {
  role: 1
};

function subscriber(state = initState, action) {
  switch (action.type) {
    case "UPDATE_ROLE":
      return {
        ...state,
        role: action.payload.role
      };
    default:
      return state;
  }
}

export default subscriber;
