// import { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import { withSnackbar } from 'notistack';
// // import {removeSnackbar} from '../utils/notifier'
// import { removeSnackbar } from '../actions/NotifierActions';

// class Notifier extends Component {
//   state = {
//     displayed: []
//   };

//   storeDisplayed = (key) => {
//     this.setState(({ displayed }) => ({
//       displayed: [...displayed, key]
//     }));
//   };

//   render() {
//     const { notifications, enqueueSnackbar, removeSnackbar } = this.props;
//     const { displayed } = this.state;

//     notifications.forEach((notification) => {
//       setTimeout(() => {
//         // If notification already displayed, abort
//         if (displayed.indexOf(notification.key) > -1) return;
//         // Display notification using notistack
//         enqueueSnackbar(notification.message, notification.options);
//         // Add notification's key to the local state
//         this.storeDisplayed(notification.key);
//         // Dispatch action to remove the notification from the redux store
//         removeSnackbar(notification.key);
//       }, 1);
//     });

//     return null;
//   }
// }

// const mapStateToProps = (store) => ({
//   notifications: store.notifications.notifications
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators({ removeSnackbar }, dispatch);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withSnackbar(Notifier));

// https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/Notifier.js:0-1767

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../actions/NotifierActions';

let displayed = [];

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(store => store.notifications.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
