import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { getFirebase } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, change } from "redux-form";
import FileUploader from "react-firebase-file-uploader";
import { Field } from "redux-form";
import { Detector } from "react-detect-offline";
import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  RenderFormSection,
  RenderTextField,
  RenderHiddenField,
  StyledProgress
} from "@austere-monorepo/components";

// Icons
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "338px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: `1px solid ${theme.palette.divider}`
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

function FormImagesArray(props) {
  const { fields, formName, maxImages, height, width } = props;

  const classes = useStyles();

  const currentImages = useSelector(
    (state) => state.form[formName].values && state.form[formName].values.images
  );

  const dispatch = useDispatch();

  const firebase = getFirebase();

  // Get the UID of the user uploading
  const uid = useSelector((state) => state.firebase && state.firebase.auth.uid);
  const storageRef = firebase.storage().ref(uid);

  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleUploadStart = () => {
    setUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => setProgress(progress);

  const handleUploadError = (error) => {
    setUploading(false);
    console.error(error);
  };

  async function handleImageDelete(currentImage) {
    console.log(currentImage);
    if (currentImage) {
      setDeleting(true);
      var storage = firebase.storage();
      var fileRef = await storage.refFromURL(currentImage);
      // console.log("File in database before delete exists : " + fileRef.exists());
      fileRef
        .delete()
        .then(function () {
          // setImageURL("");
          setDeleting(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else null;
  }

  return (
    <Fragment>
      {fields.map((note, index) => {
        const currentImage = currentImages[index].url;

        return (
          <RenderFormSection key={index} subHeading={`Image ${index + 1}`}>
            {!currentImage && (
              <Grid item md={7} xs={12}>
                <FileUploader
                  // hidden
                  accept="image/jpg"
                  randomizeFilename
                  maxWidth={width ? width : 600} // 4:3 ratio
                  maxHeight={height ? height : 338} // 4:3 ratio
                  storageRef={storageRef}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={(filename) => {
                    setProgress(100);
                    setUploading(false);
                    if (firebase) {
                      firebase
                        .storage()
                        .ref(uid)
                        .child(filename)
                        .getDownloadURL()
                        .then((url) =>
                          dispatch(
                            change(formName, `images[${index}].url`, url)
                          )
                        )
                        .then()
                        .catch(function (error) {
                          console.log(error);
                        });
                    }
                  }}
                  onProgress={handleProgress}
                  // metadata={{ cacheControl: "max-age=3600" }}
                />
              </Grid>
            )}
            {currentImage && (
              <Grid item md={7} xs={12}>
                <Box
                  style={{ backgroundImage: `url(${currentImage})` }}
                  className={classes.image}
                />
              </Grid>
            )}

            <Grid item md={5} xs={12}>
              <Grid container direction="column" spacing={3}>
                <Field
                  name={`${note}.url`}
                  type="text"
                  component={RenderHiddenField}
                  label="Notes"
                />
                <Field
                  name={`${note}.note`}
                  type="text"
                  multiline
                  maxRows={11}
                  component={RenderTextField}
                  label="Notes"
                />
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.deleteButton}
                    startIcon={<DeleteIcon />}
                    onClick={async () => {
                      await handleImageDelete(currentImage).then(() =>
                        fields.remove(index)
                      );
                    }}
                  >
                    {currentImage ? "Delete Image" : "Cancel"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </RenderFormSection>
        );
      })}
      <Grid item xs={12}>
        <Button
          // fullWidth
          variant="outlined"
          color="secondary"
          startIcon={fields.length >= maxImages ? null : <AddIcon />}
          disabled={fields.length >= (maxImages ? maxImages : 6)}
          onClick={() => fields.push({ url: "", note: "" })}
        >
          {fields.length >= (maxImages ? maxImages : 6)
            ? "Max. Images Reached"
            : "Add Image"}
        </Button>
      </Grid>
      {deleting && <StyledProgress message={"Deleting image"} />}
      {uploading && (
        <StyledProgress message={"Uploading image " + progress + "%"} />
      )}
    </Fragment>
  );
}

function FormImages(props) {
  const { subHeading, height, width, maxImages } = props;
  const formName = props.form;
  return (
    <Fragment>
      <RenderFormSection
        heading={"Images"}
        id="images"
        subHeading={
          subHeading ? subHeading : "Select images to attach to this form"
        }
      >
        <Detector
          render={({ online }) => (
            <Fragment>
              {online ? (
                <FieldArray
                  name="images"
                  component={FormImagesArray}
                  formName={formName}
                  maxImages={maxImages}
                  height={height}
                  width={width}
                  {...props}
                />
              ) : (
                <Grid item xs={12}>
                  Image upload is disabled offline. Connect to a network, or
                  take photos with your device camera and add to this form when
                  you're back online.
                </Grid>
              )}
            </Fragment>
          )}
        />
      </RenderFormSection>
    </Fragment>
  );
}

FormImages.propTypes = {
  subHeading: PropTypes.string,
  maxImages: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number
};

export default FormImages;
