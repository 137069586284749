import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { NoData } from "@austere-monorepo/components";
import posts from "../../posts.json";
import backgroundImage from "assets/background.jpg";
// Icons
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import InfoIcon from "@material-ui/icons/Info";
import HealingIcon from "@material-ui/icons/Healing";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";

// Styles
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    minHeight: "calc(100vh - 56px)"
  },
  card: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    margin: 0
    // opacity: 0.95
  },
  cardHeader: {
    "& .MuiCardHeader-avatar": {
      color: theme.palette.primary.main,
      fontWeight: 500
    },
    "& .MuiCardHeader-content": {
      "& .MuiTypography-root": {
        color: theme.palette.primary.main
      }
    }
  },
  listItemText: {
    // fontSize: "15px",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  action: {
    padding: 0,
    justifyContent: "flex-end"
  },
  iconBox: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: theme.spacing(1, 0)
  }
}));

const Home = (props) => {
  const classes = useStyles();
  const { _notes, _role } = props;
  // const sortedPosts = fastSort(postList

  const icons = {
    Medical: <LocalHospitalIcon />,
    Trauma: <HealingIcon />,
    Environmental: <FilterHdrIcon />,
    Essentials: <InfoIcon />
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="stretch" spacing={2}>
        {_role === 1 && (
          <Grid xs={12} item>
            <Alert
              variant="filled"
              severity="info"
              action={
                <Button
                  component={Link}
                  to={"/account"}
                  color="inherit"
                  size="small"
                >
                  UPGRADE
                </Button>
              }
            >
              You are currently using the Free Plan. Upgrade to access Premium
              Features.
            </Alert>
          </Grid>
        )}
        {posts &&
          posts.map((section, i) => (
            <Fragment key={i}>
              {section.name !== null && (
                <Grid item xs={6} sm={6} md={3} style={{ display: "flex" }}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={icons[section.name]}
                      title={`${section.name}`}
                    />
                    <List dense disablePadding>
                      {section.children &&
                        section.children.map((item, i) => (
                          <ListItem
                            key={i}
                            button
                            disableGutters
                            component={Link}
                            to={`/guidelines/${item.path}`}
                          >
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={item.name}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Card>
                </Grid>
              )}
            </Fragment>
          ))}
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader
              title={"My Recent N.O.T.E.S"}
              avatar={<LocalHospitalIcon />}
              className={classes.cardHeader}
            />
            {_role === 2 && (
              <Fragment>
                <List dense disablePadding>
                  {_notes &&
                    _notes.slice(0, 5).map((note) => (
                      <ListItem
                        dense
                        disablePadding
                        button
                        disableGutters
                        key={note.id}
                        component={Link}
                        to={`notes/view/${note.id}`}
                        style={{ padding: 0 }}
                      >
                        <ListItemText
                          primary={`${note.description} | 
                          ${
                            note.dob ? moment().diff(note.dob, "y") + "yo" : "-"
                          } 
                          ${note.gender ? note.gender : "-"}`}
                          secondary={moment(note.date).format("DD-MMM-YYYY")}
                        />
                      </ListItem>
                    ))}
                </List>
                <NoData array={_notes} message={"No N.O.T.E.S forms"} />
              </Fragment>
            )}
            {_role !== 2 && (
              <Link to="/account/">Subscribe to save NOTES forms</Link>
            )}
            <CardActions className={classes.action}>
              <Button
                size="small"
                component={Link}
                to={"/notes"}
                endIcon={<RightIcon />}
                disabled={_role !== 2}
              >
                All N.O.T.E.S
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card className={classes.card}>
            <CardHeader
              title={"Connect with Survive"}
              avatar={<GroupAddIcon />}
              className={classes.cardHeader}
            />

            <Box className={classes.iconBox}>
              <IconButton
                component="a"
                target="_blank"
                href="http://www.facebook.com/survivefirstaid"
              >
                <FacebookIcon />
              </IconButton>

              <IconButton
                component="a"
                target="_blank"
                href="https://www.instagram.com/survivefirstaid/"
              >
                <InstagramIcon />
              </IconButton>

              <IconButton
                component="a"
                target="_blank"
                href="https://www.youtube.com/channel/UCEGgMLiPm5gKQ52aLVRM4gQ"
              >
                <YouTubeIcon />
              </IconButton>

              <IconButton
                component="a"
                target="_blank"
                href="https://au.linkedin.com/in/adam-kershaw-96a8ab6b"
              >
                <LinkedInIcon />
              </IconButton>

              <IconButton
                component="a"
                target="_blank"
                href="https://twitter.com/SurviveFirstAid"
              >
                <TwitterIcon />
              </IconButton>

              <IconButton
                component="a"
                target="_blank"
                href="mailto:info@survivefirstaid.com.au"
              >
                <MailOutlineIcon />
              </IconButton>
            </Box>
            <CardActions className={classes.action}>
              <Button
                size="small"
                component="a"
                target="_blank"
                href="https://www.survivefirstaid.com.au/book-online"
                endIcon={<RightIcon />}
              >
                Book Your Next Course
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  firebase: { auth },
  firestore: { ordered, data }
}) => ({
  _user: auth,
  _notes: ordered.notes && ordered.notes,
  _role: data.owner && data.owner.role
});

export default compose(connect(mapStateToProps))(Home);
